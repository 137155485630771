import React, { ReactElement, useContext } from 'react';
import { Card, Typography, Spacer, Button, Icon } from 'mino-ui';
import * as S from './styles';
import * as T from './types';
import { SingleDealContext } from 'presentation/pages/Deal/contexts/SingleDealContext';
import { formatBrazilianDate, formatToReal } from 'main/helpers';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { useFunnels } from 'main/hooks/useFunnels';
import { useTranslation } from 'react-i18next';
interface CardProps {
  deal?: T.DealCardProps;
}
const DealCard = (props: CardProps): ReactElement => {
  const { funnels } = useFunnels();
  const { setEditDeal, all_custom_fields } = useContext(SingleDealContext);
  const { deal } = props;
  const { t } = useTranslation();
  return (
    <>
      <S.Container>
        <Card direction="column">
          <S.Header>
            <S.TitleWrap>
              <S.IconTittle>
                <Icon iconName="Briefcase24Regular" color={IconColors.blue} />
              </S.IconTittle>
              <Typography
                variant="body"
                color="dark"
                fontSize={14}
                weight="bold"
                lineHeight={14}
                noWrap
              >
                {t('deal.details')}
              </Typography>
            </S.TitleWrap>
            <Button
              type="button"
              variant="ghost"
              text=""
              size="small"
              icon="Edit20Regular"
              onClick={() => setEditDeal(true)}
            />
          </S.Header>
          <S.Body>
            <Spacer height={16} />
            <S.ListWrap>
              {deal?.value ? (
                <S.Item>
                  <Icon
                    iconName="ShoppingBag20Regular"
                    color={IconColors.dark}
                  />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="dark"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.value')}: </span>
                      {formatToReal(deal.value)}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}
              {deal ? (
                <S.Item>
                  <Icon
                    iconName="CalendarLtr20Regular"
                    color={IconColors.dark}
                  />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="gray"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.createdAt')}: </span>
                      {deal.created_at &&
                        formatBrazilianDate({
                          date: new Date(deal.created_at),
                        })}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}
              {deal ? (
                <S.Item>
                  <Icon
                    iconName="ColumnTriple20Regular"
                    color={IconColors.dark}
                  />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="gray"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.stage')}: </span>
                      {funnels.find((f) => f.id === deal?.id_funnel)?.name}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}

              {deal?.user ? (
                <S.Item>
                  <Icon iconName="Person20Regular" color={IconColors.dark} />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="gray"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.owner')}: </span>
                      {deal.user?.name}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}

              {deal?.origin ? (
                <S.Item>
                  <Icon iconName="Channel20Regular" color={IconColors.dark} />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="gray"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.channel')}: </span>
                      {deal.origin}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}

              {deal?.payment ? (
                <S.Item>
                  <Icon iconName="Payment20Regular" color={IconColors.dark} />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="dark"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.payment')}: </span>
                      {deal.payment}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}

              {deal?.predict && (
                <S.Item>
                  <Icon
                    iconName="CalendarLtr20Regular"
                    color={IconColors.dark}
                  />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="dark"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.predict')}: </span>
                      {formatBrazilianDate({ date: new Date(deal.predict) })}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              )}

              {deal?.campaign ? (
                <S.Item>
                  <Icon iconName="Info20Regular" />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="dark"
                      fontSize={12}
                      weight="regular"
                    >
                      <span>{t('deal.campaign')}: </span>
                      {deal.campaign}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}
              {deal?.obs ? (
                <S.Item>
                  <Icon iconName="Info20Regular" />
                  <S.ItemWrap>
                    <Typography
                      variant="body"
                      color="dark"
                      fontSize={12}
                      weight="regular"
                      noWrap
                    >
                      <span>{t('deal.observation')}: </span>
                      {deal.obs}
                    </Typography>
                  </S.ItemWrap>
                </S.Item>
              ) : (
                ''
              )}

              {deal?.custom_fields?.map(
                (field) =>
                  field.value && (
                    <S.Item key={field.id}>
                      <Icon iconName="Info20Regular" />
                      <S.ItemWrap>
                        <Typography
                          variant="body"
                          color="dark"
                          fontSize={12}
                          weight="regular"
                          ellipsis
                          noWrap
                          fill
                        >
                          <span>
                            {
                              all_custom_fields.find(
                                (f) => f.id === field.custom_field_id
                              )?.name
                            }
                          </span>{' '}
                          {field.value}
                        </Typography>
                      </S.ItemWrap>
                    </S.Item>
                  )
              )}
            </S.ListWrap>
          </S.Body>
        </Card>
      </S.Container>
    </>
  );
};

export default DealCard;
