import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Box, Drawer, Input } from 'mino-ui';
import { makeSaveServiceUseCase } from 'main/factories/usecases/service/save-service-factory';
import { RequestPayloadService } from 'domain/usecases/service/save-services-use-case';
import { useForm } from 'react-hook-form';
import {
  moneyMask,
  formatPriceForBackend,
} from 'main/helpers/input-mask-money';
import { useTranslation } from 'react-i18next';

interface ManageDrawerProps {
  callback: () => void;
  updatePayload?: RequestPayloadService;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { callback, isOpen, setIsOpen, updatePayload } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function saveUser(data: RequestPayloadService) {
    const { price } = data;
    try {
      setLoading(true);

      await makeSaveServiceUseCase(data.id).execute(
        { ...data, price: price && formatPriceForBackend(`${price}`) },
        'update'
      );

      callback();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<RequestPayloadService>();

  const inputPrice = watch('price');

  useEffect(() => {
    if (updatePayload) {
      setValue('id', updatePayload.id);
      setValue('name', updatePayload.name);
      setValue('price', updatePayload.price);
    }
  }, [updatePayload, setValue]);

  useEffect(() => {
    const maskedPrice = moneyMask(`${inputPrice}`);
    setValue('price', maskedPrice);
  }, [inputPrice, setValue]);

  const submitRef = createRef<HTMLInputElement>();

  return (
    <Drawer
      submitText={t('services.save')}
      title={t('services.updateProduct')}
      onClose={() => {
        setIsOpen(false);
      }}
      onSubmit={() => submitRef.current?.click()}
      isOpen={isOpen}
      loadingSubmit={loading}
    >
      <form onSubmit={handleSubmit(saveUser)}>
        <Box gap={8} flexDirection="column">
          <Input register={register} hidden name="id" />

          <Input
            label={t('services.name')}
            placeholder={t('services.newProduct')}
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
              minLength: 3,
              maxLength: 42,
            }}
          />

          <Input
            label={t('services.price')}
            autoComplete="off"
            placeholder={t('services.price')}
            name="price"
            register={register}
            errors={errors}
          />

          <input ref={submitRef} hidden type="submit" />
        </Box>
      </form>
    </Drawer>
  );
};
