import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { collaborators, segments, whyINeedCRM } from 'config/consts/register';
import { Button, Select, Spacer } from 'mino-ui';
import { SignUpContext } from 'presentation/contexts';
import { useTranslation } from 'react-i18next';
const StepTwo = (): ReactElement => {
  const [validate, SetValidate] = useState(false);
  const { t } = useTranslation();

  const {
    setStep,
    setBreadCrumb,
    step,
    setTitle,
    handleSelectInputChange,
    form,
  } = useContext(SignUpContext);

  function handleChangeStep(): void {
    setStep(3);
  }

  useEffect(() => {
    setTitle({
      title: t('register.title'),
      subtitle: t('register.subtitle'),
    });
  }, []);

  useEffect(() => {
    setBreadCrumb([
      {
        title: t('register.myData'),
        action: () => undefined,
        active: step === 1 ? true : false,
      },
      {
        title: t('register.companyData'),
        action: () => undefined,
        active: step === 2 ? true : false,
      },
      {
        title: t('register.password'),
        action: () => undefined,
        active: step === 3 ? true : false,
      },
    ]);
  }, []);

  useEffect(() => {
    if (form.segment !== '' && form.crmFor !== '' && form.companySize !== '') {
      SetValidate(true);
    } else {
      SetValidate(false);
    }
  }, [form]);

  return (
    <>
      <Select
        fullWidth
        onChange={(v) =>
          v?.value && handleSelectInputChange('segment', v.value)
        }
        label={t('register.segment')}
        placeholder={t('register.select')}
        options={segments}
      />

      <Spacer height={24} />

      <Select
        fullWidth
        onChange={(v) =>
          v?.value && handleSelectInputChange('companySize', v.value)
        }
        label={t('register.companySize')}
        placeholder={t('register.select')}
        options={collaborators}
      />

      <Spacer height={24} />

      <Select
        fullWidth
        onChange={(v) => v?.value && handleSelectInputChange('crmFor', v.value)}
        label={t('register.crmFor')}
        placeholder={t('register.select')}
        options={whyINeedCRM}
      />
      <Spacer height={24} />

      <Button
        disabled={!validate}
        full
        text={t('register.next')}
        onClick={() => handleChangeStep()}
      />
    </>
  );
};

export default StepTwo;
