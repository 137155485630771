import React, { ReactElement, useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { defaultInputValidationMessages } from 'config/defaultInputValidationMessages';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Label from '../Label';
import * as S from './styles';
import { SelectProps } from './types';

const CustomSelect = (props: SelectProps): ReactElement => {
  const {
    options,
    placeholder = 'Selecione',
    onChange,
    label,
    fullWidth,
    defaultValue,
    multi = false,
    onInputChange,
    isLoading,
    control,
    name,
    required,
    creatable,
    onCreateOption,
    value,
    disabled,
  } = props;

  const [stateOptions, setOptions] = useState(options);

  const styles = {
    fullWidth,
  };

  useEffect(() => {
    setOptions(options);
  }, [options]);

  return (
    <S.Container {...styles}>
      {label && <Label text={label} />}

      {control && !creatable ? (
        <Controller
          control={control}
          name={name || 'fieldName'}
          rules={{
            required: {
              value: required || false,
              message: defaultInputValidationMessages.required(),
            },
          }}
          render={({
            field: { onChange, onBlur, value, ref, name },
            formState: { errors },
          }) => (
            <>
              <Select
                isDisabled={disabled}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                name={name}
                ref={ref}
                onBlur={onBlur}
                onInputChange={onInputChange}
                styles={S.ReactSelectCustomStyles}
                options={stateOptions}
                placeholder={placeholder}
                isMulti={multi}
                isLoading={isLoading}
                isClearable={false}
                noOptionsMessage={() => 'Nenhum resultado'}
                value={
                  !multi
                    ? stateOptions.find((opt) => opt.value === value)
                    : Array.isArray(value) &&
                      stateOptions.filter((opt) => value.includes(opt.value))
                }
                onChange={(opt: any) => {
                  if (!multi) {
                    onChange(opt.value);
                  } else {
                    onChange(opt.map((v) => v.value));
                  }
                }}
                defaultValue={
                  !multi
                    ? stateOptions.find((opt) => opt.value === defaultValue)
                    : Array.isArray(defaultValue) &&
                      stateOptions.filter((opt) =>
                        defaultValue.includes(opt.value)
                      )
                }
              />

              {errors && (
                <ErrorMessage
                  errors={errors}
                  name={name || 'fieldName'}
                  render={({ message }) => <p>{message}</p>}
                />
              )}
            </>
          )}
        />
      ) : creatable ? (
        <Creatable
          isDisabled={disabled}
          onBlur={(e) => e.target.blur()}
          defaultValue={
            options.find((option) => option.value === defaultValue) || undefined
          }
          onCreateOption={onCreateOption}
          menuPortalTarget={document.body}
          onInputChange={onInputChange}
          styles={S.ReactSelectCustomStyles}
          options={stateOptions}
          placeholder={placeholder}
          isMulti={multi}
          onChange={onChange as any}
          value={value}
          isLoading={isLoading}
          formatCreateLabel={(inputValue) => `Criar "${inputValue}"`}
          noOptionsMessage={() => 'Nenhum resultado'}
        />
      ) : (
        <Select
          isDisabled={disabled}
          onBlur={(e) => e.target.blur()}
          defaultValue={
            options.find((option) => option.value === defaultValue) || undefined
          }
          menuPortalTarget={document.body}
          onInputChange={onInputChange}
          styles={S.ReactSelectCustomStyles}
          options={stateOptions}
          placeholder={placeholder}
          isMulti={multi}
          onChange={onChange as any}
          isLoading={isLoading}
          noOptionsMessage={() => 'Nenhum resultado'}
          value={value}
        />
      )}
    </S.Container>
  );
};

export default CustomSelect;
