import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import enTranslation from './locales/en/translation.json';
import ptTranslation from './locales/pt/translation.json';

// Configure i18next
i18n
  // Pass the i18n instance to react-i18next
  .use(initReactI18next)
  // Initialize i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
    },
    lng: localStorage.getItem('i18nextLng') || 'en', // Use stored language or default to Portuguese
    fallbackLng: 'pt',
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

// Add language detector after initialization to avoid the error
i18n.use(LanguageDetector);

export default i18n;
