/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { defaultInputValidationMessages } from '../../../config/defaultInputValidationMessages';
import { ReactHookFormValidations } from './types';

export const generateValidations = (validations: ReactHookFormValidations) => {
  const { required, minLength, maxLength, min, max, pattern } = validations;

  return {
    required: required && {
      value: true,
      message: defaultInputValidationMessages.required(),
    },
    minLength: minLength && {
      value: minLength,
      message: defaultInputValidationMessages.minLength(minLength),
    },
    maxLength: maxLength && {
      value: maxLength,
      message: defaultInputValidationMessages.maxLength(maxLength),
    },
    min: min && {
      value: min,
      message: defaultInputValidationMessages.min(min),
    },
    max: max && {
      value: max,
      message: defaultInputValidationMessages.max(max),
    },
    pattern: pattern && {
      value: pattern,
      message: defaultInputValidationMessages.pattern(),
    },
  };
};
