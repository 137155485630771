import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Drawer, Input, Button, Spacer, Select } from 'mino-ui';
import * as S from './styles';
import { ICompany } from 'domain/entity/ICompany';
import { useForm } from 'react-hook-form';
import { RequestPayloadCompany } from 'domain/usecases/company/save-company-use-case';
import { makeSaveCompanyUseCase } from 'main/factories/usecases/company/save-company-factory';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { SingleCompanyContext } from '../../contexts/SingleCompanyContext';
import { useTranslation } from 'react-i18next';

interface EditDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  company: ICompany;
  callback: () => void;
}

const EditCompanyDrawer = (props: EditDrawerProps): ReactElement => {
  const { company, isDrawerOpen, setDrawerOpen, callback } = props;
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);

  const { all_custom_fields } = useContext(SingleCompanyContext);

  const [userSearch, setUserSearch] = useState('');
  const { users, fetchUsers } = useUsers();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm<RequestPayloadCompany>();

  async function updateCompany(data: RequestPayloadCompany) {
    try {
      setLoad(true);

      const custom_fields: RequestPayloadCompany['custom_fields'] = {};

      for (const field of all_custom_fields) {
        custom_fields['cf_' + field.id] = data[`cf_${field.id}`] || '';
      }

      await makeSaveCompanyUseCase(company.id).execute(
        {
          ...data,
          custom_fields,
        },
        'update'
      );
      callback();
      setDrawerOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    setValue('name', company?.name);
    setValue('cnpj', company?.cnpj);
    setValue('description', company?.description);
    setValue('email', company?.email);
    setValue('phone_number', company?.phone_number);
    setValue('site', company?.site);
    setValue('address', company?.address);
    setValue('city', company?.city);
    setValue('state', company?.state);
    setValue('user_id', company.user_id);

    if (company.custom_fields?.length) {
      company.custom_fields.forEach((field) => {
        setValue(`cf_${field.custom_field_id}` as any, field.value);
      });
    }
  }, [setValue, company]);

  useDebouncedEffect(
    () => {
      if (userSearch.length > 0) {
        fetchUsers(userSearch);
      }
    },
    [userSearch],
    300
  );

  return (
    <Drawer
      title={t('company.edit')}
      isOpen={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      width={300}
    >
      <form onSubmit={handleSubmit(updateCompany)}>
        <S.EditDrawerFormWrap>
          <Input
            autoFocus
            label={t('company.name')}
            placeholder="Ex: Netflix"
            type="text"
            required
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Input
            label={t('company.email')}
            placeholder="Ex: email@email.com"
            type="text"
            name="email"
            register={register}
            validations={{
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            }}
          />
          <Input
            label={t('company.phone')}
            placeholder="Ex: (99)99999-9999"
            type="text"
            name="phone_number"
            register={register}
          />
          <Input
            label={t('company.cnpj')}
            placeholder="Ex: 00.000.000/0000-00"
            type="text"
            name="cnpj"
            register={register}
          />
          <Input
            label={t('company.site')}
            placeholder="Ex: www.site.com"
            type="text"
            name="site"
            register={register}
          />

          <Input
            label={t('company.address')}
            placeholder=""
            type="text"
            name="address"
            register={register}
          />

          <Input
            label={t('company.city')}
            placeholder=""
            type="text"
            name="city"
            register={register}
          />

          <Select
            fullWidth
            name="state"
            control={control}
            placeholder={t('company.selectState')}
            label={t('company.state')}
            options={BrazilianStates.map((es) => ({
              label: es.nome,
              value: es.sigla,
            }))}
          />

          <Select
            fullWidth
            name="user_id"
            onInputChange={(v) => setUserSearch(v)}
            control={control}
            defaultValue={company.user?.id}
            placeholder={t('company.selectOwner')}
            label={t('company.owner')}
            options={users.map((u) => ({
              label: u.name,
              value: u.id,
            }))}
          />
          <Input
            label={t('company.description')}
            placeholder="Ex: lorem ipsum.."
            type="text"
            name="description"
            register={register}
          />

          {all_custom_fields?.map((field) => (
            <Input
              key={field.id}
              full
              label={field.name}
              placeholder={field.name}
              type="text"
              name={`cf_${field.id}`}
              register={register}
            />
          ))}

          <Spacer height={32} />
          <S.ButtonsWrap>
            <Button
              size="medium"
              type="submit"
              variant="primary"
              disabled={load}
              text={load ? t('company.saving') : t('company.save')}
              full
            />
            <Button
              onClick={() => setDrawerOpen(false)}
              size="medium"
              type="button"
              variant="ghost"
              text={t('company.cancel')}
              full
            />
          </S.ButtonsWrap>
        </S.EditDrawerFormWrap>
      </form>
    </Drawer>
  );
};
export default EditCompanyDrawer;
