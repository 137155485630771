import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Dialog, Select, Spacer, Tag, Typography } from 'mino-ui';
import { IContact } from 'domain/entity/IContact';
import { makeUpdateContactUseCase } from 'main/factories/usecases/contact/update-contact-factory';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { SingleContactContext } from 'presentation/pages/Contact/contexts/SingleContactContext';
import { makeListTagUseCase } from 'main/factories/usecases/tags/list-tags-factory';
import { ITag } from 'domain/entity/ITag';
import { useTranslation } from 'react-i18next';

interface EditTagsDialogProps {
  contact: IContact;
  callback: () => void;
  tags: ITag[];
}

const AttachTagsDialog = (props: EditTagsDialogProps): ReactElement => {
  const { callback, contact, tags: contactTags } = props;
  const { t } = useTranslation();
  const { addTagsDialog, setAddTagsDialog } = useContext(SingleContactContext);

  const [load, setLoad] = useState(false);

  const [loadingTags, setLoadingTags] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<ITag[]>([]);
  const [, setSearch] = useState('');

  const [attach_tags, setAttachTags] = useState<string[]>([]);
  const [detach_tags, setDetachTags] = useState<string[]>([]);

  const [tags, setTags] = useState(
    contactTags?.map((c) => ({
      id: c.id,
      name: c.name,
      color: c.color,
    }))
  );

  const fetchTags = useCallback(async () => {
    try {
      setLoadingTags(true);
      const data = await makeListTagUseCase().execute();
      setTagsOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTags(false);
    }
  }, [setTagsOptions]);

  const handleAttachTags = useCallback(async () => {
    try {
      setLoad(true);
      await makeUpdateContactUseCase(contact.id).execute({
        name: contact.name,
        attach_tags: attach_tags,
        detach_tags: detach_tags,
      });

      fetchTags();
      callback();
      setSearch('');
      setLoad(false);
      setAddTagsDialog(false);
    } catch (error) {
      console.log(error);
    }
  }, [
    fetchTags,
    setSearch,
    attach_tags,
    detach_tags,
    setAddTagsDialog,
    callback,
    contact.id,
    contact.name,
  ]);

  const attachTags = useCallback(
    (id: string, name: string, color: string) => {
      if (attach_tags.includes(id) || tags.map((c) => c.id).includes(id)) {
        return;
      }

      setAttachTags([...attach_tags, id]);

      const newDetach = detach_tags.filter((a) => a !== id);
      setDetachTags(newDetach);

      const newState = tags.filter((c) => c.id !== id);
      const newArray = tagsOptions.filter((a) => a.id == id);
      console.log(newArray);

      setTags([
        ...newState,
        {
          id,
          name,
          color,
        },
      ]);
    },
    [
      attach_tags,
      setTags,
      tags,
      setAttachTags,
      detach_tags,
      setDetachTags,
      tagsOptions,
    ]
  );

  const detachTags = useCallback(
    (id: string) => {
      if (detach_tags.includes(id)) return;
      setDetachTags([...detach_tags, id]);

      const newAttach = attach_tags.filter((a) => a !== id);
      setAttachTags(newAttach);

      const newState = tags.filter((c) => c.id !== id);
      setTags(newState);
    },
    [attach_tags, setTags, tags, setAttachTags, detach_tags, setDetachTags]
  );

  useDebouncedEffect(fetchTags, [fetchTags], 200);

  const reset = useCallback(() => {
    setAttachTags([]);
    setDetachTags([]);
  }, [setAttachTags, setDetachTags]);

  useEffect(reset, [reset]);

  return (
    <Dialog
      title={t('contact.editTags')}
      open={addTagsDialog}
      onOpenChange={() => setAddTagsDialog(false)}
      onSubmit={handleAttachTags}
      loading={load}
      content={
        <>
          <Box flexDirection="column" gap={16}>
            <Select
              fullWidth
              isLoading={loadingTags}
              onInputChange={(value) => {
                setSearch(value);
              }}
              label={t('contact.selectTag')}
              placeholder={t('contact.selectTag')}
              options={tagsOptions
                .filter((c) => !tags.map((c) => c.id).includes(c.id))
                .map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              onChange={(d) =>
                d &&
                attachTags(
                  d.value,
                  d.label || '',
                  `${tagsOptions
                    .filter(function (id) {
                      return id.id == d.value;
                    })
                    .map((tag) => tag.color)}`
                )
              }
            />
          </Box>

          <Spacer height={16} />

          <Box flexDirection="column" justifyContent="center" gap={16}>
            <Typography variant="body" weight="bold" fontSize={14} color="dark">
              {t('contact.selected')}
            </Typography>

            <Box gap={8} flexDirection="row" style={{ flexWrap: 'wrap' }}>
              {tags?.map((t) => (
                <Tag
                  key={t.id}
                  name={t.name}
                  color={t.color || ''}
                  onClose={() => detachTags(t.id)}
                />
              ))}
            </Box>
          </Box>
        </>
      }
    />
  );
};
export default AttachTagsDialog;
