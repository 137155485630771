import React, { ReactElement, useState } from 'react';
import {
  BottomBar,
  Box,
  Button,
  Card,
  Dialog,
  SideBar,
  Topbar,
  Typography,
} from 'mino-ui';
import { ISession } from 'domain/entity/ISession';
import * as S from './styles';
import { bodyLinks, footerLinks } from './route-links';
import { useMatch, useNavigate } from 'react-router';
import { ensureAuthentication } from 'main/middlewares/EnsureAuthentication';
import Routes from 'main/routes';
import GoogleAuthButton from 'presentation/pages/Activity/components/Google/GoogleButton';
import GoogleContactsPhoto from './images/google-contacts.png';
import GoogleCalendarPhoto from './images/google-calendar.png';
import GooglePhoto from './images/google.svg';
import { makeUpdateProfileUseCase } from 'main/factories/usecases/profile/update-profile-use-factory';
import { useToken } from 'main/hooks/usetoken';

type TemplateProps = {
  session: ISession;
};

export const LayoutTemplate = (props: TemplateProps): ReactElement => {
  const { session } = props;
  const { logout } = useToken();

  const [sideWidth, setSideWidth] = useState(208);

  //todo = once google is verified, add the initial state as session.onboarding
  const [onboarding, setOnboarding] = useState(1);

  const isAuthenticated = ensureAuthentication();

  const navigate = useNavigate();

  async function updateOnboarding(reload?: boolean) {
    makeUpdateProfileUseCase(session.id)
      .execute({
        email: session.email,
        username: session.username,
        onboarding: 1,
      })
      .then(() => {
        if (reload) {
          setOnboarding(1);
        }
      });
  }

  return (
    <S.BodyWrapper>
      {isAuthenticated ? (
        <SideBar
          onCollapsed={(value) => setSideWidth(value ? 208 : 68)}
          bodyLinks={bodyLinks}
          footerLinks={footerLinks}
          matchRoute={useMatch}
          navigator={navigate}
          externalNavigator={(to: string) => {
            window.open(to, '_blank');
          }}
        />
      ) : null}

      <S.MainWrapper offset={isAuthenticated ? sideWidth : 0}>
        {isAuthenticated ? (
          <Topbar
            handleLogout={() => logout()}
            navigate={navigate}
            username={session.username || ''}
            avatar={session.avatar}
            hasActivePhoneNumber={false}
          />
        ) : null}

        <Routes />

        <BottomBar navigate={navigate} />

        {onboarding === 0 ? (
          <Dialog
            hideCloseButton
            open={true}
            modal
            trigger={() => null}
            title={`Olá ${session?.username?.split(' ')[0]}, Seja bem vindo!`}
            content={
              <Card direction="column" gap={48}>
                <Typography color="dark" noWrap lineHeight={22} variant="body">
                  Para aproveitar ainda mais o Mino CRM, integre sua conta do
                  Google para buscar seus contatos e eventos.
                </Typography>

                <Box gap={16} justifyContent="center">
                  <img
                    width="60"
                    height="60"
                    src={GoogleContactsPhoto}
                    alt="Google Contacts"
                  />
                  <img
                    width="60"
                    height="60"
                    src={GoogleCalendarPhoto}
                    alt="Google Calendar"
                  />
                  <img width="60" height="60" src={GooglePhoto} alt="Google" />
                </Box>

                <Box
                  flexDirectionMobile="column"
                  gap={16}
                  justifyContent="center"
                >
                  <GoogleAuthButton
                    onClick={() => updateOnboarding()}
                    active={true}
                  />

                  <Button
                    text="Agora não"
                    onClick={() => updateOnboarding(true)}
                    variant="ghost"
                  />
                </Box>
              </Card>
            }
          />
        ) : null}
      </S.MainWrapper>
    </S.BodyWrapper>
  );
};
