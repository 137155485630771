import React, { ReactElement, useContext } from 'react';
import { Collapse, Drawer, Input, Select, Spacer } from 'mino-ui';
import { useForm } from 'react-hook-form';
import ContactListContext from './context/ContactListContext';
import { RequestParamsListContacts } from 'domain/usecases/contact/list-contact-use-case';
import * as S from './styles';
import { useChannels } from 'main/hooks/useChannel';
import FilterIcon from './icons/filter.svg';
import { useCompanies } from 'main/hooks/useCompanies';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { useTranslation } from 'react-i18next';

const DrawerFilterContact = (): ReactElement => {
  const { filterOpen, setFilterOpen, filter, setFilter, initialFilter } =
    useContext(ContactListContext);
  const { t } = useTranslation();
  const { channels } = useChannels();
  const { companies } = useCompanies();

  function handleFilter(data: RequestParamsListContacts): void {
    setFilter({ ...data });
    setFilterOpen(false);
  }

  function resetFilter() {
    reset();
    setFilter(initialFilter);
    setFilterOpen(false);
  }

  const { register, handleSubmit, reset, control } =
    useForm<RequestParamsListContacts>({
      defaultValues: {
        search: '',
        channel_id: undefined,
        company_id: undefined,
        state: undefined,
        context: 'self',
      },
    });

  const { custom_fields } = useCustomFields('contact');

  return (
    <Drawer
      width={300}
      isOpen={filterOpen}
      onClose={() => setFilterOpen(false)}
      title={t('contact.filters')}
      icon={FilterIcon}
      submitText={t('contact.filter')}
      onSubmit={handleSubmit(handleFilter)}
      secondarySubmitText={t('contact.clear')}
      secondarySubmitAction={() => {
        resetFilter();
      }}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <S.DrawerForm>
          <Collapse
            defaultOpen={true}
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>
                  {t('contact.personalInformation')}
                </S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <>
                <Input
                  autoFocus
                  register={register}
                  label={t('contact.name')}
                  name="search"
                  placeholder={t('contact.search')}
                />
                <Input
                  register={register}
                  label={t('contact.email')}
                  name="email"
                  placeholder={t('contact.email')}
                />
                <Input
                  register={register}
                  label={t('contact.cpf')}
                  name="cpf"
                  placeholder={t('contact.cpf')}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('contact.owner')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                label={t('contact.owner')}
                defaultValue={filter.context}
                control={control}
                name="context"
                options={[
                  {
                    value: 'self',
                    label: t('contact.my'),
                  },
                  {
                    value: 'all',
                    label: t('contact.all'),
                  },
                ]}
              />
            }
          />

          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('contact.source')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                name="channel_id"
                control={control}
                placeholder={t('contact.source')}
                label={t('contact.source')}
                options={[
                  {
                    label: t('contact.none'),
                    value: '',
                  },
                  ...channels.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })),
                ]}
              />
            }
          />
          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('contact.company')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                name="company_id"
                control={control}
                placeholder={t('contact.company')}
                label={t('contact.company')}
                options={[
                  {
                    label: t('contact.none'),
                    value: '',
                  },
                  ...companies.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })),
                ]}
              />
            }
          />
          <Collapse
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('contact.address')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <>
                <Select
                  fullWidth
                  name="state"
                  control={control}
                  placeholder={t('contact.state')}
                  label={t('contact.state')}
                  options={BrazilianStates.map((es) => ({
                    label: es.nome,
                    value: es.sigla,
                  }))}
                />
                <Input
                  register={register}
                  label={t('contact.city')}
                  name="city"
                  placeholder={t('contact.city')}
                />
                <Input
                  register={register}
                  label={t('contact.zip')}
                  name="zip"
                  placeholder={t('contact.zip')}
                />
              </>
            }
          />

          {custom_fields?.map((field) => (
            <Collapse
              key={field.id}
              hasArrow
              trigger={
                <S.WrapTrigger>
                  <S.TriggerText>{field.name}</S.TriggerText>
                </S.WrapTrigger>
              }
              content={
                <>
                  <Input
                    label={field.name}
                    full
                    register={register}
                    name={`cf_${field.id}`}
                    placeholder={`${field.name}`}
                  />
                </>
              }
            />
          ))}

          <Spacer height={16} />
        </S.DrawerForm>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};

export default DrawerFilterContact;
