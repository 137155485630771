import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { makeRegisterSessionUseCase } from 'main/factories/usecases/sessions/register-session-factory';
import { Box, Button, Input, Select, Spacer, Typography } from 'mino-ui';
import { SignUpContext } from 'presentation/contexts';
import { makeToastify } from 'main/factories/notification';
import { useTranslation } from 'react-i18next';

const StepThree = (): ReactElement => {
  const { t } = useTranslation();
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [passValidation, setPassValidation] = useState({
    valid: false,
    error: '',
  });

  const { setTitle, setBreadCrumb, step, form, setForm } =
    useContext(SignUpContext);

  useEffect(() => {
    setTitle({
      title: t('register.title'),
      subtitle: t('register.subtitle'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadCrumb([
      {
        title: t('register.myData'),
        action: () => undefined,
        active: step === 1 ? true : false,
      },
      {
        title: t('register.companyData'),
        action: () => undefined,
        active: step === 2 ? true : false,
      },
      {
        title: t('register.confirmation'),
        action: () => undefined,
        active: step === 3 ? true : false,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleValidatePass(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    if (value === pass) {
      setPassValidation({
        valid: true,
        error: '',
      });
      return;
    }

    setPassValidation({
      valid: false,
      error: t('register.passwordNotMatch'),
    });
  }

  async function handleFormSubmit() {
    const toast = makeToastify();
    if (!form.quantity || !form.licenseId) {
      toast.show(t('register.fillPlanAndQuantity'), {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      await makeRegisterSessionUseCase().execute(form);

      window.location.href = '/';
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (passValidation.valid && pass) {
      setForm({ ...form, password: pass });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pass, passValidation]);

  const [plans, setPlans] = useState<any[]>([]);

  async function fetchPlans() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + '/session/products'
      );

      setPlans(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <>
      <Typography weight="bold" variant="body">
        {t('register.selectPlan')}
      </Typography>
      <Spacer height={16} />
      <Box
        flexDirection="column"
        gap={8}
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 4,
          padding: 16,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box gap={8}>
          <Input
            label={t('register.users')}
            placeholder={t('register.users')}
            name="company"
            value={form.quantity?.toString()}
            type="number"
            onChange={(e) =>
              setForm({ ...form, quantity: Number(e.target.value) })
            }
          />
          <Select
            fullWidth
            required
            label={t('register.plan')}
            placeholder={t('register.select')}
            name="plan"
            options={plans.map((plan) => ({
              label: plan.name,
              value: plan.price_id,
            }))}
            onChange={(v) => v && setForm({ ...form, licenseId: v.value })}
          />
        </Box>
      </Box>

      <Spacer height={24} />

      <Spacer height={16} />
      <Input
        full
        label={t('register.password')}
        placeholder={t('register.password')}
        type="password"
        name="password"
        onChange={(e) => setPass(e.target.value)}
        value={pass}
      />
      <Spacer height={24} />
      <Input
        full
        onChange={handleValidatePass}
        label={t('register.confirmPassword')}
        placeholder={t('register.confirmPassword')}
        type="password"
        name="confirm_pass"
        autoComplete="off"
        errors={passValidation.error}
      />
      <Spacer height={24} />
      <Button
        disabled={!passValidation.valid || loading}
        full
        text={loading ? t('register.loading') : t('register.finish')}
        onClick={() => handleFormSubmit()}
      />
    </>
  );
};

export default StepThree;
