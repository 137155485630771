import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { IContact } from 'domain/entity/IContact';
import { Box, Drawer, Avatar, Select, Spacer, Typography } from 'mino-ui';
import {
  makeListContactUseCase,
  makeUpdateDealUseCase,
} from 'main/factories/usecases';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import CloseIcon from './icons/close.svg';
import ContactIcon from './icons/user.svg';
import { useTranslation } from 'react-i18next';

type ContactDrawerProps = {
  contacts: IContact[];
  isOpen: boolean;
  openOpen: (val: boolean) => void;
  callback: () => void;
};

export const ContactDrawer = (props: ContactDrawerProps): ReactElement => {
  const { contacts: contactsProps, isOpen, openOpen, callback } = props;
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contactsOptions, setContactsOptions] = useState<IContact[]>([]);
  const [contacts, setContacts] = useState(
    contactsProps.map((c) => ({
      id: c.id,
      name: c.name,
    }))
  );

  const [search, setSearch] = useState('');

  const fetchContacts = useCallback(async () => {
    try {
      setLoadingContacts(true);
      const { data } = await makeListContactUseCase().execute({
        search,
      });
      setContactsOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingContacts(false);
    }
  }, [search, setContactsOptions]);

  const [attach_contacts, setAttachContacts] = useState<string[]>([]);
  const [detach_contacts, setDetachContacts] = useState<string[]>([]);

  const attachContacts = useCallback(
    (id: string, name: string) => {
      if (
        attach_contacts.includes(id) ||
        contacts.map((c) => c.id).includes(id)
      ) {
        return;
      }

      setAttachContacts([...attach_contacts, id]);

      const newDetach = detach_contacts.filter((a) => a !== id);
      setDetachContacts(newDetach);

      const newState = contacts.filter((c) => c.id !== id);
      setContacts([...newState, { id, name }]);
    },
    [
      attach_contacts,
      setContacts,
      contacts,
      setAttachContacts,
      detach_contacts,
      setDetachContacts,
    ]
  );

  const detachContacts = useCallback(
    (id: string) => {
      if (detach_contacts.includes(id)) return;
      setDetachContacts([...detach_contacts, id]);

      const newAttach = attach_contacts.filter((a) => a !== id);
      setAttachContacts(newAttach);

      const newState = contacts.filter((c) => c.id !== id);
      setContacts(newState);
    },
    [
      setContacts,
      contacts,
      setContacts,
      detach_contacts,
      setDetachContacts,
      attach_contacts,
      setAttachContacts,
    ]
  );

  const handleAttachContacts = useCallback(async () => {
    try {
      await makeUpdateDealUseCase(id).execute({
        attach_contacts: attach_contacts,
        detach_contacts: detach_contacts,
      });

      fetchContacts();
      setSearch('');
      callback();
      openOpen(false);
    } catch (error) {
      console.log(error);
    }
  }, [fetchContacts, setSearch, attach_contacts, detach_contacts]);

  useDebouncedEffect(fetchContacts, [fetchContacts], 200);

  const reset = useCallback(() => {
    setAttachContacts([]);
    setDetachContacts([]);
  }, [isOpen, setAttachContacts, setDetachContacts]);

  useEffect(reset, [reset]);

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => openOpen(false)}
      title={t('deal.linkContact')}
      icon={ContactIcon}
      submitText={t('deal.save')}
      onSubmit={handleAttachContacts}
      secondarySubmitText={t('deal.cancel')}
      secondarySubmitAction={() => {
        openOpen(false);
      }}
    >
      <Box gap={16} flexDirection="column">
        <Select
          isLoading={loadingContacts}
          onInputChange={(value) => {
            setSearch(value);
          }}
          label={t('deal.addContact')}
          placeholder={t('deal.searchContact')}
          options={contactsOptions
            .filter((c) => !contacts.map((c) => c.id).includes(c.id))
            .map((contact) => ({
              value: contact.id,
              label: contact.name,
            }))}
          onChange={(d) => d && attachContacts(d.value, d.label || '')}
        />

        <Spacer height={16} />

        <Box flexDirection="column" justifyContent="center" gap={16}>
          <Typography variant="body" weight="bold">
            {t('deal.linkedContacts')}
          </Typography>

          {contacts.map((contact) => (
            <Box key={contact.id} alignItems="center" gap={8}>
              <Box
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                gap={8}
              >
                <Box gap={8} alignItems="center">
                  <Avatar name={contact.name} />
                  <Typography color="gray" variant="body" weight="bold">
                    {contact.name}
                  </Typography>
                </Box>

                <ReactSVG
                  onClick={() => detachContacts(contact.id)}
                  src={CloseIcon}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};
