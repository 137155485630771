import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Button, Input, Typography, RichText } from 'mino-ui';
import {
  ActionButton,
  EmptyStateMail,
  WrapButtons,
  WrapContent,
} from '../styles';
import Clock from '../icons/clock.svg';

import NewEmail from '../icons/newEmailButton.svg';
import { ActivityContext } from 'presentation/pages/Activity/context/ActivityContext';
import { useForm } from 'react-hook-form';
import { UploadFile } from './UploadFile';
import { makeSendCustomMail } from 'main/factories/usecases';
import { makeSaveFileUseCase } from 'main/factories/usecases/file/save-file-factory';
import { ActivityStatus, ActivityType } from 'domain/entity/IActivity';
import { useTranslation } from 'react-i18next';

type ActivityPayload = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  status: ActivityStatus;
  type: ActivityType;
};

const EmailTab = (): ReactElement => {
  const { handleSubmit, setScheduleActivityType, setOpenSchedule, emails } =
    useContext(ActivityContext);

  const hasEmails = emails && emails?.length > 0;

  const [files, setFiles] = useState<FileList>();

  async function submitActivity(data: ActivityPayload) {
    try {
      await handleSubmit(data, true);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setScheduleActivityType(ActivityType.EMAIL);
  }, [setScheduleActivityType]);

  const {
    register,
    formState: { errors },
    handleSubmit: handleFormSubmit,
    setValue,
  } = useForm<ActivityPayload>({
    defaultValues: {
      title: '',
      type: ActivityType.EMAIL,
      description: 'Registro de E-mail',
      start: new Date(),
      end: new Date(),
      status: ActivityStatus.FINISHED,
    },
  });

  const { t } = useTranslation();
  const mailsTo = useMemo(() => {
    return emails?.map((email) => email.email);
  }, [emails]);

  const ccTo = useMemo(() => {
    return [];
  }, []);

  const [ccToInput, setCcToInput] = useState<string>('');

  function handleAddCC(value: string) {
    const emailRegex = new RegExp(
      '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );

    if (value && emailRegex.test(value)) {
      ccTo?.push(value as never);
    }

    setCcToInput('');
  }

  function handleRemoveAllCC() {
    ccTo?.splice(0, ccTo.length);
  }

  const [hasCC, setHasCC] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultSignature = '';
  const [mailContent, setMailContent] = useState<string>(defaultSignature);

  const attachments: string[] = [];

  async function saveFiles() {
    try {
      if (files?.length) {
        // Check file sizes first
        const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > MAX_FILE_SIZE) {
            throw new Error('One or more files exceed the 10MB size limit');
          }
        }

        // If all files are within size limit, proceed with upload
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const response = await makeSaveFileUseCase().execute({
            file,
          });

          attachments.push(response.url || '');
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendMail(data: ActivityPayload) {
    const { title } = data;

    if (!mailsTo) return;
    if (ccToInput) handleAddCC(ccToInput);

    try {
      setLoading(true);

      if (files && files.length > 0) {
        //guid
        await saveFiles();
      }

      const response = await makeSendCustomMail().execute({
        message: mailContent,
        subject: title,
        to: mailsTo,
        cc: ccTo,
        files: attachments,
      });

      if (response.message) {
        submitActivity({
          type: ActivityType.EMAIL,
          description: mailContent,
          start: new Date(),
          end: new Date(),
          status: ActivityStatus.FINISHED,
          title: title,
        });

        setMailContent(defaultSignature);
        setValue('title', '');
        handleRemoveAllCC();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setFiles(undefined);
    }
  }
  /* 
  if (session.id && !session?.custom_email) {
    return (
      <EmptyStateMail>
        <Typography noWrap variant="body" color="gray" fontSize={14}>
          Seu usuário não possui um e-mail vinculado, por favor, entre em
          contato com o administrador do sistema.
        </Typography>
      </EmptyStateMail>
    );
  } */

  if (!hasEmails)
    return (
      <Box flexDirection="column" gap={8}>
        <EmptyStateMail>
          <Typography noWrap variant="body" color="gray" fontSize={14}>
            {t('activities.noEmail')}
          </Typography>
        </EmptyStateMail>

        <Button
          size="medium"
          text={t('activities.createReminder')}
          icon={Clock}
          variant="ghostSecondary"
          type="button"
          onClick={() => setOpenSchedule(true)}
          noWrap
        />
      </Box>
    );

  return (
    <WrapContent>
      <form onSubmit={handleFormSubmit(sendMail)}>
        <Box gap={8} flexDirection="column">
          <Box gap={8} alignItems="flex-end">
            <Input
              full
              autoFocus
              placeholder={t('activities.emailSubject')}
              name="title"
              label={t('activities.email')}
              register={register}
              errors={errors}
              validations={{
                required: true,
              }}
            />
          </Box>

          <Box flexDirection="row" justifyContent="flex-start" gap={4}>
            <Typography
              variant="body"
              fontSize={12}
              noWrap
              style={{
                backgroundColor: '#F5f5f5',
                padding: 4,
                borderRadius: 4,
              }}
            >
              <b>{t('activities.to')}:</b> {mailsTo?.join(', ')}
            </Typography>
          </Box>

          <ActionButton onClick={() => setHasCC(!hasCC)}>
            {t('activities.cc')}
          </ActionButton>

          {hasCC ? (
            <Box gap={8} style={{ maxWidth: 300 }}>
              <Input
                name="cc"
                full
                autoFocus
                placeholder={t('activities.email')}
                onChange={(e) => setCcToInput(e.target.value)}
                value={ccToInput}
                onEnter={() => {
                  handleAddCC(ccToInput);
                  setCcToInput('');
                }}
              />
            </Box>
          ) : null}

          {ccTo && ccTo?.length > 0 ? (
            <Box alignItems="center" gap={8}>
              <Box>
                <Typography
                  variant="body"
                  fontSize={12}
                  style={{
                    backgroundColor: '#F5f5f5',
                    padding: 4,
                    borderRadius: 4,
                  }}
                >
                  <b>{t('activities.cc')}:</b> {ccTo?.join(', ')}
                </Typography>
              </Box>

              <Box>
                <ActionButton onClick={() => handleRemoveAllCC()}>
                  <span>{t('activities.remove')}</span>
                </ActionButton>
              </Box>
            </Box>
          ) : null}
        </Box>

        <RichText onChange={(v) => setMailContent(v)} value={mailContent} />

        <UploadFile files={files} setFiles={setFiles} />

        <WrapButtons>
          {emails && emails?.length > 0 ? (
            <Button
              size="medium"
              text={t('activities.send')}
              icon={NewEmail}
              variant="primary"
              type="button"
              onClick={handleFormSubmit(sendMail)}
              loading={loading}
              noWrap
            />
          ) : null}

          <Button
            size="medium"
            text={t('activities.createReminder')}
            icon={Clock}
            variant="ghostSecondary"
            type="button"
            onClick={() => setOpenSchedule(true)}
            noWrap
          />
        </WrapButtons>
      </form>
    </WrapContent>
  );
};
export default EmailTab;
