export const pt = {
  // Input validation messages
  required: 'Campo obrigatório',
  minLength: 'Deve conter pelo menos {0} caracteres',
  maxLength: 'Não pode conter mais que {0} caracteres',
  min: 'Não pode ser menor que {0}',
  max: 'Não pode ser maior que {0}',
  pattern: 'Esse campo está inválido',

  // Dialog component
  save: 'Salvar',
  cancel: 'Cancelar',
};
