import { IDeal } from './IDeal';
import { IContact } from './IContact';
import { IUser } from './IUser';
import { ICompany } from './ICompany';
import { IFile } from './IFile';

export enum ActivityType {
  CALL = 'call',
  MEETING = 'meeting',
  NOTE = 'note',
  EMAIL = 'email',
  MESSAGE = 'message',
  PROPOSAL = 'proposal',
  CREATED = 'created',
  UPDATED = 'updated',
  SALE = 'sale',
  LOSS = 'loss',
  TASK = 'task',
}

export enum ActivityStatus {
  OPEN = 0,
  FINISHED = 1,
  SCHEDULED = 2,
}

export interface IActivity {
  id: string;
  title: string;
  type: ActivityType;
  calling_result?: boolean;
  user_id?: string;
  user?: IUser;
  contact_id?: string;
  deal_id?: string;
  company_id?: string;
  description?: string;
  status: ActivityStatus;
  start: Date;
  end?: Date;
  allDay?: boolean;
  contact?: IContact;
  company?: ICompany;
  deal?: IDeal;
  created_at: string;
  updated_at: string;
  on_google?: boolean;
  files?: IFile[];
}

export function translateActivityType(type: ActivityType, t: any): string {
  switch (type) {
    case ActivityType.CALL:
      return t('activities.call');
    case ActivityType.MEETING:
      return t('activities.meeting');
    case ActivityType.NOTE:
      return t('activities.note');
    case ActivityType.EMAIL:
      return t('activities.email');
    case ActivityType.MESSAGE:
      return t('activities.message');
    case ActivityType.PROPOSAL:
      return t('activities.proposal');
    case ActivityType.CREATED:
      return t('activities.created');
    case ActivityType.UPDATED:
      return t('activities.updated');
    case ActivityType.SALE:
      return t('activities.sale');
    case ActivityType.LOSS:
      return t('activities.loss');
    case ActivityType.TASK:
      return t('activities.task');
    default:
      return t('activities.activity');
  }
}

export function translateTextToActivityType(
  type: string,
  t: any
): ActivityType {
  switch (type) {
    case t('activities.call'):
      return ActivityType.CALL;
    case t('activities.meeting'):
      return ActivityType.MEETING;
    case t('activities.note'):
      return ActivityType.NOTE;
    case t('activities.email'):
      return ActivityType.EMAIL;
    case t('activities.message'):
      return ActivityType.MESSAGE;
    case t('activities.proposal'):
      return ActivityType.PROPOSAL;
    case t('activities.created'):
      return ActivityType.CREATED;
    case t('activities.updated'):
      return ActivityType.UPDATED;
    case t('activities.sale'):
      return ActivityType.SALE;
    case t('activities.loss'):
      return ActivityType.LOSS;
    case t('activities.task'):
      return ActivityType.TASK;
    default:
      return ActivityType.CALL;
  }
}
