import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

interface LanguageContextType {
  language: string;
  changeLanguage: (lang: string) => void;
}

export const LanguageContext = createContext<LanguageContextType>({
  language: 'pt',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeLanguage: () => {
    /* Default implementation, will be overridden */
  },
});

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng') || 'pt'
  );

  const changeLanguage = (lang: string) => {
    try {
      if (typeof lang === 'string' && (lang === 'en' || lang === 'pt')) {
        // Set language directly in localStorage
        localStorage.setItem('i18nextLng', lang);
        // Update i18n instance
        i18n.changeLanguage(lang);
        // Update state
        setLanguage(lang);
      }
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  useEffect(() => {
    // Initialize with stored language
    const storedLang = localStorage.getItem('i18nextLng');
    if (storedLang && (storedLang === 'en' || storedLang === 'pt')) {
      i18n.changeLanguage(storedLang);
      setLanguage(storedLang);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
