import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Datepicker,
  Dialog,
  Dropdown,
  Input,
  Label,
  RichText,
  Select,
  ToggleGroup,
} from 'mino-ui';
import { useForm } from 'react-hook-form';
import { makeSaveActivityUseCase } from 'main/factories/usecases/activity/save-activity-factory';
import { durationOptions } from './options';
import {
  ActivityStatus,
  ActivityType,
  IActivity,
  translateActivityType,
} from 'domain/entity/IActivity';
import { DealResponseDTO } from 'domain/dtos/deal/deal-response-dto';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { makeListDealsUseCase } from 'main/factories/usecases';
import { useContacts } from 'main/hooks/useContacts';
import { useCompanies } from 'main/hooks/useCompanies';
import { UploadFile } from '../../ActivityTabs/atoms/UploadFile';
import { makeSaveFileUseCase } from 'main/factories/usecases/file/save-file-factory';
import { RichTextWrap } from './styles';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { useTranslation } from 'react-i18next';
export type ScheduleActivityForm = Props['activity'] & {
  start: Date;
  end?: Date;
  allDay?: boolean;
  title: string;
  type: ActivityType;
  duration?: string;
  description?: string;
};

type Props = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  trigger: ReactElement;
  callback(): void;
  hideContext?: boolean;
  activity?: Omit<
    IActivity,
    'created_at' | 'updated_at' | 'contact' | 'company' | 'deal' | 'user'
  >;
  initialType?: ActivityType;
  initialContext?: 'deal' | 'contact' | 'company';
  initialContextId?: string;
  initialStart?: Date;
  initialEnd?: Date;
};

const ScheduleActivityDialog = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    activity,
    callback,
    trigger,
    initialContext,
    initialContextId,
    initialType,
    hideContext,
    initialStart,
    initialEnd,
  } = props;

  const { session } = useContext(SessionContext);

  const [context, setContext] = useState<'deal' | 'contact' | 'company'>(
    'deal'
  );
  const [loading, setLoading] = useState(false);
  const [searchDeal, setSearchDeal] = useState<string>('');
  const [searchContact, setSearchContact] = useState<string>('');
  const [searchCompany, setSearchCompany] = useState<string>('');

  const [deals, setDeals] = useState<DealResponseDTO>();
  const { fetchContacts, contacts } = useContacts();
  const { fetchCompanies, companies } = useCompanies();
  const [files, setFiles] = useState<FileList>();

  const [descriptionEditor, setDescriptionEditor] = useState(
    activity?.description
  );

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<ScheduleActivityForm>({
    defaultValues: {
      type: initialType || activity?.type || ActivityType.CALL,
      start: initialStart || activity?.start || new Date(),
      status: ActivityStatus.SCHEDULED,
      duration: '30',
      title: activity?.title || '',
      description: activity?.description || '',
      deal_id: activity?.deal_id?.toString() || '',
      contact_id: activity?.contact_id?.toString() || '',
      company_id: activity?.company_id?.toString() || '',
      allDay: activity?.allDay || false,
      calling_result: activity?.calling_result || false,
      end: initialEnd || activity?.end || undefined,
      id: activity?.id || undefined,
      on_google: activity?.on_google || false,
      files: activity?.files || [],
      user_id: activity?.user_id || session.id,
    },
  });

  async function fetchDeals(search: string) {
    try {
      const response = await makeListDealsUseCase().execute({
        result: undefined,
        context: 'all',
        funnel_id: '',
        search,
        page: 1,
      });

      setDeals(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSaveFiles(activityId: string) {
    try {
      const filesArray = Array.from(files || []);

      await Promise.all(
        filesArray.map(async (file) => {
          await makeSaveFileUseCase(activityId).execute({
            file,
          });
        })
      );

      setFiles(undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveActivitySchedule(data: ScheduleActivityForm) {
    try {
      setLoading(true);

      const endDate = new Date(
        new Date(data.start).getTime() + Number(data.duration) * 60000
      );

      const response = await makeSaveActivityUseCase(data?.id).execute(
        {
          user_id: session.id,
          title: data.title,
          type: data.type,
          start: data.start,
          end: endDate,
          allDay: data.allDay,
          status: ActivityStatus.SCHEDULED,
          description: descriptionEditor,
          deal_id:
            initialContext === 'deal' && initialContextId
              ? initialContextId
              : data?.deal_id?.toString() || undefined,
          contact_id:
            initialContext === 'contact' && initialContextId
              ? initialContextId
              : data?.contact_id?.toString() || undefined,
          company_id:
            initialContext === 'company' && initialContextId
              ? initialContextId
              : data?.company_id?.toString() || undefined,
        },
        data.id ? 'update' : 'create'
      );

      if (files && files.length) {
        await handleSaveFiles(response.id);
      }

      callback();
      reset();
      setDescriptionEditor('');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useDebouncedEffect(
    () => {
      fetchDeals(searchDeal);
    },
    [searchDeal],
    300
  );

  useDebouncedEffect(
    () => {
      fetchContacts(searchContact);
    },
    [searchContact],
    300
  );

  useDebouncedEffect(
    () => {
      fetchCompanies(searchCompany);
    },
    [searchCompany],
    300
  );

  useEffect(() => {
    if (initialStart) {
      setValue('start', initialStart);
    }
    if (initialEnd) {
      setValue('end', initialEnd);
    }
  }, [initialStart, initialEnd, setValue]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  useEffect(() => {
    setDescriptionEditor(activity?.description || '');
  }, [activity?.description]);

  useEffect(() => {
    if (initialType) {
      setValue('type', initialType);
    }
  }, [initialType, setValue]);

  useEffect(() => {
    function getInitialDurationByStartAndEnd() {
      if (activity?.start && activity?.end) {
        const start = new Date(activity.start);
        const end = new Date(activity.end);

        const duration = Math.floor((end.getTime() - start.getTime()) / 60000);
        setValue('duration', duration.toString());
        return;
      }

      setValue('duration', '30');
    }

    getInitialDurationByStartAndEnd();
  }, [activity, setValue]);

  return (
    <Dialog
      open={open}
      loading={loading}
      maxWidth={520}
      trigger={trigger}
      onOpenChange={(open) => {
        !open && reset();
        setOpen?.(open);
      }}
      onSubmit={handleSubmit(saveActivitySchedule)}
      title={activity?.id ? t('activities.edit') : t('activities.create')}
      content={
        <form onSubmit={handleSubmit(saveActivitySchedule)}>
          <Box gap={16} flexDirection="column">
            {/*  {google.active &&
            !activity?.deal_id &&
            !activity?.contact_id &&
            !activity?.company_id ? (
              <Box alignItems="center">
                <Checkbox
                  control={control}
                  label="Vincular Google Calendar"
                  name="on_google"
                />
              </Box>
            ) : null} */}

            <Box>
              <Dropdown
                label={t('activities.selectType')}
                placeholder={t('activities.selectType')}
                items={[
                  {
                    value: ActivityType.CALL,
                    label: t('activities.call'),
                  },
                  {
                    value: ActivityType.EMAIL,
                    label: t('activities.email'),
                  },
                  {
                    value: ActivityType.MEETING,
                    label: t('activities.meeting'),
                  },
                  {
                    value: ActivityType.NOTE,
                    label: t('activities.note'),
                  },
                  {
                    value: ActivityType.PROPOSAL,
                    label: t('activities.proposal'),
                  },
                  {
                    value: ActivityType.MESSAGE,
                    label: t('activities.message'),
                  },
                ]}
                initialSelectedItem={{
                  value: getValues('type'),
                  label: translateActivityType(
                    getValues('type') as ActivityType,
                    t
                  ),
                }}
                onChange={(value) =>
                  typeof value === 'string' &&
                  setValue('type', value as unknown as ActivityType)
                }
              />
            </Box>

            {!hideContext &&
            !activity?.deal_id &&
            !activity?.contact_id &&
            !activity?.company_id ? (
              <Box flexDirection="column" gap={8}>
                <Box flexDirection="column">
                  <Label text={t('activities.context')} />
                  <ToggleGroup
                    value={context}
                    onValueChange={(value) =>
                      typeof value === 'string'
                        ? setContext(value as typeof context)
                        : undefined
                    }
                    type="single"
                    size="small"
                    defaultValue="contact"
                    items={[
                      {
                        value: 'contact',
                        label: t('activities.contact'),
                        icon: '',
                      },
                      {
                        value: 'deal',
                        label: t('activities.deal'),
                        icon: '',
                      },
                      {
                        value: 'company',
                        label: t('activities.company'),
                        icon: '',
                      },
                    ]}
                  />
                </Box>

                {context === 'deal' && (
                  <Select
                    fullWidth
                    defaultValue={activity?.deal_id?.toString()}
                    name="deal_id"
                    control={control}
                    required
                    placeholder={t('activities.selectDeal')}
                    label={t('activities.deal')}
                    onInputChange={(value) => setSearchDeal(value)}
                    options={
                      deals
                        ? deals.data.map((d) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        : []
                    }
                  />
                )}

                {context === 'contact' && (
                  <Select
                    fullWidth
                    name="contact_id"
                    control={control}
                    required
                    placeholder={t('activities.selectContact')}
                    label={t('activities.contact')}
                    onInputChange={(value) => setSearchContact(value)}
                    options={contacts?.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}

                {context === 'company' && (
                  <Select
                    fullWidth
                    name="company_id"
                    control={control}
                    required
                    placeholder={t('activities.selectCompany')}
                    label={t('activities.company')}
                    onInputChange={(value) => setSearchCompany(value)}
                    options={companies?.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}
              </Box>
            ) : null}

            <Input
              full
              label={t('activities.title')}
              register={register}
              errors={errors}
              autoFocus
              name="title"
              placeholder={t('activities.titlePlaceholder')}
              validations={{
                required: true,
                minLength: 5,
              }}
            />

            <Datepicker
              label={t('activities.date')}
              placeholder={t('activities.selectDate')}
              required
              control={control}
              name="start"
              time
              fullWidth={true}
            />

            <Box>
              <Select
                label={t('activities.duration')}
                name="duration"
                options={durationOptions}
                control={control}
                required
              />
            </Box>

            <RichTextWrap>
              <Label text={t('activities.comment')} />
              <RichText
                onChange={(e) => setDescriptionEditor(e)}
                value={descriptionEditor}
              />
            </RichTextWrap>

            <UploadFile setFiles={setFiles} files={files} />

            <input hidden type="submit" />
          </Box>
        </form>
      }
    />
  );
};

export default ScheduleActivityDialog;
