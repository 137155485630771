import React, { ReactElement, useEffect, useState } from 'react';
import { DealRequestPayload } from 'domain/usecases/deal/list-deals-use-case';
import { FunnelResponse } from 'domain/usecases/funnel/list-funnels-use-case';
import {
  Box,
  Button,
  Dialog,
  Dropdown,
  InputSearch,
  ToggleGroup,
  Typography,
} from 'mino-ui';
import DealContext from '../contexts/DealContext';
import DealsList from './list';
import DealPipeline from './pipeline';
import * as S from './styles';
import DrawerFilterDeal from './filterDeal';
import { isMobile } from 'react-device-detect';
import { DealResult, IDeal } from 'domain/entity/IDeal';
import { makeExportDealsFactory } from 'main/factories/usecases/deal/export-deal-factory';
import { CreateDealDialog } from 'presentation/shared/templates/Deals/dialogs/CreateDealDialog';
import { ImportDeals } from './import-deals';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useTranslation } from 'react-i18next';
interface DealPageProps {
  deals: IDeal[];
  funnels: FunnelResponse[];
  loading: boolean;
  fetchFunnels: () => void;
  fetchDeals: () => void;
  lastPage: number;
  totalDeals: number;
  activePage: number;
}

const initialFilter: DealRequestPayload = {
  funnel_id: '',
  result: DealResult.Open,
  loss_id: '',
  context: 'self',
  page: 1,
};

export enum IconColors {
  blue = 'blue',
  dark = 'dark',
  white = 'white',
  systemGreen = 'systemGreen',
  systemRed = 'systemRed',
}

const DealPage = (props: DealPageProps): ReactElement => {
  const {
    funnels,
    loading,
    fetchFunnels,
    fetchDeals,
    deals,
    activePage,
    lastPage,
    totalDeals,
  } = props;

  const { t } = useTranslation();

  const filterCache = localStorage.getItem('filter');

  const [loadingExport, setLoadingExport] = useState(false);
  const [createDeal, setCreateDeal] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [bulkOwner, setBulkOwner] = useState(false);
  const [bulkTags, setBulkTags] = useState(false);
  const [bulkFunnel, setBulkFunnel] = useState(false);
  const [bulkWon, setBulkWon] = useState(false);
  const [bulkLoss, setBulkLoss] = useState(false);

  const [view, setView] = useState<'pipeline' | 'list'>('pipeline');

  const [filter, setFilter] = useState<DealRequestPayload>(
    filterCache ? JSON.parse(filterCache) : initialFilter
  );

  const [searchInput, setSearchInput] = useState('initial');

  useDebouncedEffect(
    () => {
      if (searchInput !== 'initial')
        setFilter({
          ...filter,
          search: searchInput,
        });
    },
    [searchInput],
    500
  );
  useEffect(() => {
    localStorage.setItem('filter', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    if (isMobile) setView('list');
  }, [isMobile]);

  useEffect(() => {
    fetchDeals();
  }, [filter]);

  const [exportDialogOpen, setExportDialogOpen] = useState(false);

  async function handleExportDeals(): Promise<void> {
    try {
      setLoadingExport(true);

      const res = await makeExportDealsFactory().execute({
        ...filter,
        export: true,
      });

      if (res.success) {
        const url = process.env.REACT_APP_API_BASE_URL + res.url;
        window.open(url, '_blank');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExport(false);
      setExportDialogOpen(false);
    }
  }

  return (
    <DealContext.Provider
      value={{
        loading,
        view,
        filter,
        setFilter,
        setCreateDeal,
        createDeal,
        isFilterOpen,
        setIsFilterOpen,
        importDialogOpen,
        setImportDialogOpen,
        initialFilter,
        fetchFunnels,
        fetchDeals,
        deals,
        activePage,
        lastPage,
        totalDeals,
        bulkOwner,
        setBulkOwner,
        bulkTags,
        setBulkTags,
        bulkFunnel,
        setBulkFunnel,
        bulkWon,
        setBulkWon,
        bulkLoss,
        setBulkLoss,
      }}
    >
      <S.Toolbar>
        <Box alignItems="center" gap={16}>
          <Button
            onClick={() => setCreateDeal(true)}
            text={t('deal.create')}
            icon="AddCircle20Regular"
            iconColor={IconColors.white}
            variant="primary"
            size="medium"
            hiddenTextOnMobile
            type="button"
          />

          <S.Separator />

          <ToggleGroup
            defaultValue={view}
            type="single"
            size="large"
            value={view}
            onValueChange={(value) => {
              if (value == 'list') setView('list');
              if (value == 'pipeline') setView('pipeline');
            }}
            items={[
              {
                icon: 'GridKanban20Regular',
                value: 'pipeline',
              },
              {
                icon: 'Table20Regular',
                value: 'list',
              },
            ]}
          />
        </Box>
        <S.WrapInputSearch>
          <InputSearch
            full
            placeholder={t('deal.search')}
            name="search"
            type="text"
            value={searchInput === 'initial' ? '' : searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </S.WrapInputSearch>

        <Box gap={4} alignItems="center">
          <S.FetchButtonWrap>
            <Button
              icon="ArrowSync20Regular"
              variant="ghostSecondary"
              hiddenTextOnMobile
              type="button"
              text={t('deal.update')}
              iconColor={IconColors.dark}
              onClick={() =>
                setFilter(filterCache ? JSON.parse(filterCache) : initialFilter)
              }
            />
          </S.FetchButtonWrap>
          <S.FilterWrap>
            <Button
              text={t('deal.filters')}
              hiddenTextOnMobile
              icon="Filter20Filled"
              iconColor={IconColors.blue}
              variant="ghost"
              type="button"
              onClick={() => setIsFilterOpen(true)}
            />

            {initialFilter !== filter && <S.FilterBadge data-state="float" />}
          </S.FilterWrap>

          <Dropdown
            align="end"
            trigger="action-button"
            items={[
              {
                label: t('deal.export'),
                value: 'export',
              },
            ]}
            onChange={(value) => {
              if (value === 'import') {
                setImportDialogOpen(true);
              }
              if (value === 'export') {
                setExportDialogOpen(true);
              }
            }}
          />
        </Box>
      </S.Toolbar>
      <Box boxMain gap={8}>
        {view === 'pipeline' ? (
          <DealPipeline loading={loading} funnels={funnels} />
        ) : (
          <DealsList />
        )}
      </Box>

      <CreateDealDialog
        open={createDeal}
        setOpen={setCreateDeal}
        callback={fetchFunnels}
      />
      <ImportDeals
        callback={() => {
          fetchDeals();
        }}
      />
      <DrawerFilterDeal />

      <Dialog
        title={t('deal.export')}
        open={exportDialogOpen}
        onOpenChange={setExportDialogOpen}
        onSubmit={handleExportDeals}
        submitText={t('deal.export')}
        loading={loadingExport}
        content={
          <Box gap={24} flexDirection="column">
            <Typography variant="body" color="gray" fontSize={14}>
              {t('deal.export')} <strong>{totalDeals}</strong> {t('deal.deals')}
            </Typography>
            <Typography color="gray" variant="body">
              {t('deal.confirm')}
            </Typography>
          </Box>
        }
      />
    </DealContext.Provider>
  );
};

export default DealPage;
