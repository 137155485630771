import { en } from './locales/en';
import { pt } from './locales/pt';
import { formatMessage } from './utils';

export type TranslationKey = keyof typeof en;

export const locales = {
  en,
  pt,
};

export type SupportedLocale = keyof typeof locales;

/**
 * Gets the current locale from localStorage or defaults to 'en'
 */
export const getCurrentLocale = (): SupportedLocale => {
  if (typeof window === 'undefined') return 'en';

  const storedLocale = localStorage.getItem('locale') as SupportedLocale;
  return storedLocale && locales[storedLocale] ? storedLocale : 'en';
};

/**
 * Translates a key based on the current locale
 */
export const t = (key: TranslationKey, ...args: any[]): string => {
  const locale = getCurrentLocale();
  const message = locales[locale][key] || locales.en[key] || key;
  return args.length > 0 ? formatMessage(message, ...args) : message;
};

export { formatMessage };
