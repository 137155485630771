import { Button, Input, Spacer } from 'mino-ui';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { ReactComponent as Caution } from 'presentation/assets/icons/caution.svg';
import { SignUpContext } from 'presentation/contexts';
import { useTranslation } from 'react-i18next';

const StepOne = (): ReactElement => {
  const [validate, SetValidate] = useState(false);
  const { t } = useTranslation();
  const { setStep, setTitle, setBreadCrumb, step, handleInputChange, form } =
    useContext(SignUpContext);

  function handleStepChange(): void {
    setStep(2);
  }

  useEffect(() => {
    setBreadCrumb([
      {
        title: t('register.myData'),
        action: () => undefined,
        active: step === 1 ? true : false,
      },
      {
        title: t('register.companyData'),
        action: () => undefined,
        active: step === 2 ? true : false,
      },
      {
        title: t('register.password'),
        action: () => undefined,
        active: step === 3 ? true : false,
      },
    ]);
  }, []);

  useEffect(() => {
    setTitle({
      title: t('register.title'),
      subtitle: t('register.subtitle'),
    });
  }, []);

  useEffect(() => {
    if (form.company !== '' && form.email !== '' && form.username !== '') {
      SetValidate(true);
    } else {
      SetValidate(false);
    }
  }, [form]);

  return (
    <>
      <Input
        full
        label={t('register.company')}
        name="company"
        onChange={handleInputChange}
        placeholder={t('register.company')}
      />
      <Spacer height={24} />
      <Input
        full
        label={t('register.adminName')}
        name="username"
        onChange={handleInputChange}
        placeholder={t('register.adminName')}
      />
      <S.SmallText>
        <Caution />
        {t('register.adminName')}
      </S.SmallText>
      <Spacer height={24} />

      <Input
        full
        onChange={handleInputChange}
        label={t('register.email')}
        name="email"
        type="email"
        placeholder={t('register.email')}
      />
      <Spacer height={24} />

      <S.WrapperSmallText>
        <p>
          {t('register.termsOfService')}
          <a href="#"> {t('register.termsOfService')}</a> e a{' '}
          <a href="#">{t('register.privacyPolicy')}.</a>
        </p>
      </S.WrapperSmallText>

      <Button
        disabled={!validate}
        full
        text={t('register.next')}
        onClick={() => handleStepChange()}
      />
    </>
  );
};

export default StepOne;
