import React, { ReactElement, useState } from 'react';
import { Box, Button, Input, Table, Typography, Dialog } from 'mino-ui';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadTeams } from 'domain/usecases/team/save-teams-use-case';
import { useTeams } from 'main/hooks/useTeams';
import { makeSaveTeamUseCase } from 'main/factories/usecases/team/save-teams-factory';
import { makeDeleteTeamUseCase } from 'main/factories/usecases/team/delete-team-factory';
import { useForm } from 'react-hook-form';
import { formatBrazilianDate } from 'main/helpers';
import { useTranslation } from 'react-i18next';

const TeamsConfig = (): ReactElement => {
  const { t } = useTranslation();

  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadTeams>();
  const [deleteListing, setDeleteListing] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);

  const { teams, fetchTeams } = useTeams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RequestPayloadTeams>();

  async function saveNewTeam(data: RequestPayloadTeams) {
    try {
      setCreateLoading(true);
      await makeSaveTeamUseCase().execute(data, 'create');

      fetchTeams();
      setValue('name', '');
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }

  async function deleteTeam(teamId: string) {
    try {
      setDeleteListing(true);
      await makeDeleteTeamUseCase(teamId).execute();

      fetchTeams();
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteListing(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form
          onSubmit={handleSubmit(saveNewTeam)}
          style={{ display: 'flex', gap: 8 }}
        >
          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            validations={{
              required: true,
              maxLength: 18,
              minLength: 3,
            }}
            placeholder="Ex: Equipe 1"
            name="name"
          />

          <Button
            disabled={createLoading}
            icon={AddIcon}
            text={t('deal.add')}
            type="submit"
          />
        </form>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: t('team.name'),
            width: 50,
          },
          {
            field: 'created',
            headerName: t('team.created'),
            hiddenOnMobile: true,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={teams.map((t) => ({
          id: t.id,
          name: t.name,
          created: formatBrazilianDate({ date: new Date(t.created_at) }),
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                onClick={() => {
                  setUpdatePayload(t);
                  setManageOpen(true);
                }}
                variant="ghost"
                text={t('deal.edit')}
              />

              <Dialog
                modal
                title={t('team.attention')}
                trigger={
                  <Button
                    size="small"
                    variant="secondary"
                    text={t('deal.delete')}
                  />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      {t('deal.deleteConfirmation')}
                    </Typography>

                    <Box gap={8}>
                      <Button
                        disabled={deleteListing}
                        onClick={() => deleteTeam(t.id)}
                        variant="primary"
                        text={
                          deleteListing ? t('deal.removing') : t('deal.confirm')
                        }
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text={t('deal.cancel')}
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          fetchTeams();
          setUpdatePayload(undefined);
        }}
      />
    </Box>
  );
};

export default TeamsConfig;
