import React, { ReactElement, useContext } from 'react';
import {
  Card,
  Typography,
  Spacer,
  Avatar,
  Accordion,
  Box,
  Button,
  Icon,
} from 'mino-ui';
import * as S from '../CompanyCard/styles';
import { ICompany } from 'domain/entity/ICompany';
import { SingleDealContext } from 'presentation/pages/Deal/contexts/SingleDealContext';
import { formatBrazilianDate, copyContent } from 'main/helpers';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface CardProps {
  companies: ICompany[];
}
const CompaniesCard = (props: CardProps): ReactElement => {
  const { setCompanyDrawerOpen, setAddCompanyDialogOpen } =
    useContext(SingleDealContext);
  const { companies } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <S.Container>
      <Card direction="column" padding="1rem 1rem 0">
        <S.Header>
          <S.TitleWrap>
            <S.IconTittle>
              <Icon
                iconName="BuildingMultiple24Regular"
                color={IconColors.blue}
              />
            </S.IconTittle>
            <Typography
              variant="body"
              color="dark"
              fontSize={14}
              weight="bold"
              noWrap
            >
              {t('deal.relatedCompanies')}
            </Typography>
          </S.TitleWrap>
          <Button
            type="button"
            variant="ghost"
            text=""
            size="small"
            icon="Edit20Regular"
            onClick={() => setCompanyDrawerOpen(true)}
          />
        </S.Header>
        <S.Body>
          {companies.length === 0 && (
            <Box flexDirection="column" gap={16}>
              <S.EmptyState>
                <Icon iconName="Info20Regular" />
                <Typography variant="body" noWrap={true} fontSize={12}>
                  {t('deal.noRelatedCompanies')}
                </Typography>
              </S.EmptyState>
              <Spacer />
            </Box>
          )}

          <S.ListWrap>
            <Accordion
              border={true}
              hasDivider={true}
              type="single"
              defaultValue={companies.length ? companies[0].name : ''}
              items={companies.map((company) => ({
                value: company.name,
                trigger: (
                  <S.Trigger>
                    <Avatar width={22} name={company.name} />
                    <Typography
                      variant="body"
                      color="primary"
                      fontSize={12}
                      weight="bold"
                      noWrap
                    >
                      {company.name}
                    </Typography>
                    <button
                      onClick={() => navigate(`/companies/${company.id}`)}
                    >
                      <Icon iconName="Open16Regular" color={IconColors.blue} />
                    </button>
                  </S.Trigger>
                ),
                content: (
                  <S.ItensWrapper>
                    {company.phone_number && (
                      <S.Item>
                        <Icon
                          iconName="Call20Regular"
                          color={IconColors.dark}
                        />
                        <Box gap={4}>
                          <Typography
                            variant="body"
                            color="dark"
                            fontSize={12}
                            weight="regular"
                            noWrap
                          >
                            <span>Telefone: </span>
                            {company?.phone_number}
                          </Typography>
                          <button
                            onClick={() =>
                              copyContent(`${company.phone_number}`)
                            }
                          >
                            <Icon
                              iconName="Copy16Regular"
                              color={IconColors.dark}
                            />
                          </button>
                        </Box>
                      </S.Item>
                    )}

                    {company.email && (
                      <S.Item>
                        <Icon
                          iconName="Mail20Regular"
                          color={IconColors.dark}
                        />
                        <Box gap={4}>
                          <Typography
                            variant="body"
                            color="dark"
                            fontSize={12}
                            weight="regular"
                            noWrap
                          >
                            <span>E-mail: </span>
                            {company.email}
                          </Typography>
                          <button
                            onClick={() => copyContent(`${company.email}`)}
                          >
                            <Icon
                              iconName="Copy16Regular"
                              color={IconColors.dark}
                            />
                          </button>
                        </Box>
                      </S.Item>
                    )}

                    {company.site && (
                      <S.Item>
                        <Icon
                          iconName="Link20Regular"
                          color={IconColors.dark}
                        />
                        <Box gap={4}>
                          <Typography
                            variant="body"
                            color="dark"
                            fontSize={12}
                            weight="regular"
                            noWrap
                          >
                            <span>Site: </span>
                            {company.site}
                          </Typography>
                          <button
                            onClick={() =>
                              window.open(`https://${company.site}`, '_blank')
                            }
                          >
                            <Icon
                              iconName="Open16Regular"
                              color={IconColors.dark}
                            />
                          </button>
                        </Box>
                      </S.Item>
                    )}

                    {company.address && company.state && (
                      <S.Item>
                        <Icon
                          iconName="Location20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="dark"
                          fontSize={12}
                          weight="regular"
                          noWrap
                        >
                          <span>Endereço: </span>
                          {`${company.address}, ${company.city}, ${company.state}`}
                        </Typography>
                      </S.Item>
                    )}

                    {company.cnpj && (
                      <S.Item>
                        <Icon
                          iconName="Info20Regular"
                          color={IconColors.dark}
                        />
                        <Typography
                          variant="body"
                          color="dark"
                          fontSize={12}
                          weight="regular"
                          noWrap
                        >
                          <span>CNPJ: </span>
                          {company.cnpj}
                        </Typography>
                      </S.Item>
                    )}

                    <S.Item>
                      <Icon
                        iconName="CalendarLtr20Regular"
                        color={IconColors.dark}
                      />
                      <Typography
                        variant="body"
                        color="dark"
                        fontSize={12}
                        weight="regular"
                        noWrap
                      >
                        <span>Criado em: </span>
                        {company.created_at &&
                          formatBrazilianDate({
                            date: new Date(company.created_at),
                          })}
                      </Typography>
                    </S.Item>
                  </S.ItensWrapper>
                ),
              }))}
            />
          </S.ListWrap>
          <S.ButtonWrap>
            <Button
              variant="ghost"
              text={t('deal.link')}
              icon="LinkSquare20Regular"
              iconColor={IconColors.blue}
              onClick={() => setCompanyDrawerOpen(true)}
            />

            <Button
              variant="ghostSecondary"
              text={t('deal.newCompany')}
              icon="AddCircle20Regular"
              iconColor={IconColors.dark}
              onClick={() => setAddCompanyDialogOpen(true)}
            />
          </S.ButtonWrap>
        </S.Body>
      </Card>
    </S.Container>
  );
};

export default CompaniesCard;
