import React, { ReactElement, useState, useEffect } from 'react';
import { Drawer, Input } from 'mino-ui';
import * as S from './styles';
import { IContact } from 'domain/entity/IContact';
import { CreateDealRequest } from 'domain/usecases/deal/create-deal-use-case';
import { useForm } from 'react-hook-form';
import { makeCreateDealUseCase } from 'main/factories/usecases';
import Case from '../icons/case.svg';
import { useTranslation } from 'react-i18next';

interface EditDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  contact: IContact;
  callback: () => void;
}

const AddDealDrawer = (props: EditDrawerProps): ReactElement => {
  const { t } = useTranslation();
  const { isDrawerOpen, setDrawerOpen, callback, contact } = props;
  const [load, setLoad] = useState(false);

  async function createDeal(data: CreateDealRequest) {
    try {
      setLoad(true);
      await makeCreateDealUseCase().execute(data);
      reset();
      callback();
      setDrawerOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    setValue('attach_contacts', [`${contact?.id}`]);
  }, [contact, isDrawerOpen]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<CreateDealRequest>();

  return (
    <Drawer
      width={300}
      isOpen={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      title={t('deal.create')}
      icon={Case}
      submitText={load ? t('deal.saving') : t('deal.save')}
      secondarySubmitText={t('deal.cancel')}
      secondarySubmitAction={() => {
        setDrawerOpen(false);
        reset();
      }}
      onSubmit={handleSubmit(createDeal)}
    >
      <form onSubmit={handleSubmit(createDeal)}>
        <S.EditDrawerForm>
          <Input
            autoFocus
            label={t('deal.description')}
            placeholder={t('deal.description')}
            type="text"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Input
            label={t('deal.value')}
            placeholder={t('deal.value')}
            type="number"
            required
            name="value"
            register={register}
            errors={errors}
          />
          <Input
            type="hidden"
            required
            name="attach_contacts[]"
            register={register}
            errors={errors}
          />
          <input type="submit" hidden />
        </S.EditDrawerForm>
      </form>
    </Drawer>
  );
};
export default AddDealDrawer;
