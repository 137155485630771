import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Dialog, Icon, Typography } from 'mino-ui';
import { DealSales } from 'presentation/shared/templates/Deals/dialogs/atoms/DealSales';
import { ISale } from 'domain/entity/ISale';
import Briefcase from '../../icons/Briefcase.svg';
import { CreateSaleResponse } from 'domain/usecases/sales/create-sale-use-case';
import { useTranslation } from 'react-i18next';
type SaleDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialSales: ISale[];
  onSalesChange: (sales: CreateSaleResponse[]) => void;
};

export const SaleDialog = (props: SaleDialogProps): ReactElement => {
  const { open, onOpenChange, initialSales, onSalesChange } = props;
  const { t } = useTranslation();

  const [sales, setSales] = useState<CreateSaleResponse[]>(initialSales);

  function handleSaleChanges(newSales: ISale[]) {
    if (newSales !== sales) {
      setSales(
        newSales.map((newSale) => ({
          discount: newSale.discount,
          id: newSale.id,
          deal_id: newSale.deal_id,
          quantity: newSale.quantity,
          product_id: newSale.product_id,
          product: newSale.product,
          total: newSale.total,
        }))
      );
    }
  }

  useEffect(() => {
    if (initialSales) {
      setSales(initialSales);
    }
  }, [initialSales, setSales]);

  return (
    <Dialog
      title={t('deal.editProducts')}
      submitText={t('deal.saveChanges')}
      onSubmit={() => onSalesChange(sales)}
      maxWidth={700}
      open={open}
      onOpenChange={onOpenChange}
      trigger={<></>}
      content={
        <Box flexDirection="column" gap={24}>
          <Box alignItems="center" gap={8}>
            <Icon color="blue" src={Briefcase} />

            <Typography variant="body" fontSize={16}>
              {t('deal.totalProducts')} &nbsp;
              <b>
                R$ {sales.reduce((acc, sale) => acc + sale.total, 0).toFixed(2)}
              </b>
            </Typography>
          </Box>

          <DealSales
            hideValueInput
            sales={sales}
            setSales={handleSaleChanges}
            setDealValues={() => undefined}
          />
        </Box>
      }
    />
  );
};
