import React, { ReactElement, useState } from 'react';
import { Box, Button, Input, Table, Typography, Dialog, Select } from 'mino-ui';
import * as S from './styles';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadTags } from 'domain/usecases/tag/save-tag-use-case';
import { useTags } from 'main/hooks/useTags';
import { useForm } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import Tag20Filled from './icons/Tag20Filled.svg';
import { useTranslation } from 'react-i18next';

const TagsConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadTags>();
  const [createLoading, setCreateLoading] = useState(false);
  const { t } = useTranslation();
  const { tags, addTag, deleteTag } = useTags();

  function handleSelectChange(value: string): void {
    setValue('color', value);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RequestPayloadTags>({
    defaultValues: { name: '', color: 'blue' },
  });

  async function saveNewTag(data: RequestPayloadTags) {
    try {
      setCreateLoading(true);
      await addTag(data);

      setValue('name', '');
      setValue('color', '');
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form
          onSubmit={handleSubmit(saveNewTag)}
          style={{ display: 'flex', gap: 8 }}
        >
          <Input
            full
            autoComplete="off"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
            placeholder={t('tags.placeholder')}
            name="name"
          />
          <Select
            fullWidth
            onChange={(v) => v && handleSelectChange(v.value)}
            defaultValue={'blue'}
            options={[
              {
                value: 'blue',
                label: t('tag.blue'),
              },
              {
                value: 'red',
                label: t('tag.red'),
              },
              {
                value: 'green',
                label: t('tag.green'),
              },
              {
                value: 'yellow',
                label: t('tag.yellow'),
              },
              {
                value: 'violet',
                label: t('tag.violet'),
              },
              {
                value: 'orange',
                label: t('tag.orange'),
              },
              {
                value: 'teal',
                label: t('tag.teal'),
              },
              {
                value: 'gray',
                label: t('tag.gray'),
              },
            ]}
            placeholder={t('tag.select')}
          />

          <Button
            disabled={createLoading}
            icon={AddIcon}
            text={t('tag.add')}
            type="submit"
          />
        </form>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: t('tag.name'),
            width: 70,
          },
          {
            field: 'color',
            headerName: t('tag.color'),
            hiddenOnMobile: true,
            align: 'center',
            width: 30,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={tags.map((c) => ({
          id: c.id,
          name: c.name,
          color: (
            <S.radioWrap color={c.color}>
              <ReactSVG src={Tag20Filled} />
            </S.radioWrap>
          ),
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                onClick={() => {
                  setUpdatePayload(c);
                  setManageOpen(true);
                }}
                variant="ghost"
                text={t('tag.edit')}
              />

              <Dialog
                modal
                title={t('tag.attention')}
                trigger={
                  <Button
                    text={t('tag.delete')}
                    size="small"
                    variant="secondary"
                  />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      {t('tag.deleteConfirmation')}
                    </Typography>

                    <Box gap={8}>
                      <Button
                        text={t('tag.delete')}
                        variant="primary"
                        onClick={() => deleteTag(c.id)}
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text={t('tag.cancel')}
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          setUpdatePayload(undefined);
        }}
      />
    </Box>
  );
};

export default TagsConfig;
