import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as S from './styles';
import { IContact } from 'domain/entity/IContact';
import {
  Box,
  Typography,
  Spacer,
  Avatar,
  Button,
  Dialog,
  Dropdown,
} from 'mino-ui';

import ActivitiesTemplate from 'presentation/shared/templates/Activitites/template';
import ProductCard from './components/ProductCard/component';
import ContactCard from './components/ContactCard/component';
import EditContactDrawer from './EditContactDrawer/component';
import { SingleContactContext } from '../contexts/SingleContactContext';
import AddDealDrawer from './addDealDrawer/component';
import CompanyCard from './components/CompanyCard/component';
import { SingleEntityPageSkeleton } from 'presentation/shared/templates/Skeletons/SingleEntityPageSkeleton';
import {
  EmailsModel,
  PhoneNumbersModel,
} from 'presentation/pages/Activity/context/ActivityContext';
import { hasPermission } from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { useNavigate } from 'react-router';
import { makeDeleteContactUseCase } from 'main/factories/usecases/contact/delete-contact-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import TagsCard from './components/TagsCard/component';
import AttachTagsDialog from './components/AttachTags/component';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { useTranslation } from 'react-i18next';

interface PageProps {
  contact: IContact;
  loading: boolean;
  callback: () => void;
}

export enum IconColors {
  dark = 'dark',
  systemRed = 'systemRed',
  blue = 'blue',
}
const SingleContactPage = (props: PageProps): ReactElement => {
  const { contact, callback, loading } = props;

  const { t } = useTranslation();

  const [editContactOpen, setEditContact] = useState(false);
  const [addDealDrawer, setAddDealDrawer] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addTagsDialog, setAddTagsDialog] = useState(false);
  const [textWidth, setTextWidth] = useState(0);

  const textContent = useRef<HTMLDivElement>(null);

  const { session } = useContext(SessionContext);

  interface GroupListProps {
    label: string;
    value: string;
    icon: string;
    iconColor?: IconColors;
  }

  interface ContactArrayInfo {
    label: string;
    icon?: string;
    iconColor?: IconColors;
  }

  const navigate = useNavigate();

  const GroupListArray: GroupListProps[] = [];

  if (hasPermission(session.permissions, 'contacts', 'write')) {
    GroupListArray.push({
      label: t('contact.edit'),
      value: 'edit',
      icon: 'Pen20Regular',
    });
  }

  if (hasPermission(session.permissions, 'contacts', 'delete')) {
    GroupListArray.push({
      label: t('contact.delete'),
      value: 'delete',
      icon: 'Delete20Regular',
      iconColor: IconColors.systemRed,
    });
  }

  const ContactInfo: ContactArrayInfo[] = [];

  if (contact.email) {
    ContactInfo.push({
      label: contact.email,
      icon: 'Mail20Regular',
    });
  }
  if (contact.phone) {
    ContactInfo.push({
      label: contact.phone,
      icon: 'Call20Regular',
    });
  }

  async function deleteContact(id: string) {
    try {
      setDeleteLoading(true);
      const res = await makeDeleteContactUseCase(id).execute();
      if (res.success) {
        navigate('/contacts');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  const phoneNumbers: PhoneNumbersModel[] = [];
  const emails: EmailsModel[] = [];

  if (contact?.phone) {
    phoneNumbers.push({
      entity: 'contact',
      name: contact.name,
      phone: contact.phone,
    });
  }

  if (contact?.email) {
    emails.push({
      entity: 'contact',
      name: contact.name,
      email: contact.email,
    });
  }

  if (contact?.company) {
    if (contact.company.phone_number) {
      phoneNumbers.push({
        entity: 'contact',
        name: contact.company.name,
        phone: contact.company.phone_number,
      });
    }

    if (contact.company.email) {
      emails.push({
        entity: 'contact',
        name: contact.company.name,
        email: contact.company.email,
      });
    }
  }

  function handleSendEmail(email: string) {
    window.open(`mailto:${email}`, '_blank');
  }

  function handleCall(number: string) {
    window.open(
      `tel:${number.replace(/\D/g, '')}`,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  }

  const handleResize = useCallback(() => {
    if (textContent.current && ContactInfo.length > 0) {
      setTextWidth(textContent.current.offsetWidth + 48 + 8);
    }
  }, [textContent, ContactInfo]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const { custom_fields } = useCustomFields('contact');

  return (
    <SingleContactContext.Provider
      value={{
        editContactOpen,
        setEditContact,
        addDealDrawer,
        setAddDealDrawer,
        contact,
        addTagsDialog,
        setAddTagsDialog,
        all_custom_fields: custom_fields,
      }}
    >
      <S.Toolbar>
        <S.ToolbarLeftContent>
          <Avatar width={32} name={contact?.name || ''} />

          <Typography
            variant="body"
            color="dark"
            fontSize={18}
            weight="bold"
            overflowHidden
            width={`calc(100% - ${textWidth}px)`}
          >
            {contact.name}
          </Typography>
        </S.ToolbarLeftContent>
        <S.ToolbarRightContent ref={textContent}>
          <Box alignItems="center" justifyContent="center" gap={16}>
            {ContactInfo.map((c, index) =>
              index < ContactInfo.length - 1 ? (
                <S.ArrayInfoWrap key={index}>
                  <Box alignItems="center" justifyContent="center" gap={4}>
                    <Button
                      text={c.label}
                      variant="ghost"
                      icon={c.icon}
                      iconColor={IconColors.blue}
                      onClick={() => handleSendEmail(c.label)}
                    />
                  </Box>

                  <S.Separator />
                </S.ArrayInfoWrap>
              ) : (
                <S.ArrayInfoWrap key={index}>
                  <Box alignItems="center" justifyContent="center" gap={4}>
                    <Button
                      text={c.label}
                      variant="ghost"
                      icon={c.icon}
                      iconColor={IconColors.blue}
                      onClick={() => {
                        handleCall(c.label);
                      }}
                    />
                  </Box>
                </S.ArrayInfoWrap>
              )
            )}

            <Dropdown
              align="end"
              trigger="action-button"
              items={GroupListArray}
              onChange={(value) => {
                if (value === 'edit') {
                  setEditContact(true);
                }
                if (value === 'delete') {
                  setDialogOpen(true);
                }
              }}
            />
          </Box>
        </S.ToolbarRightContent>
      </S.Toolbar>
      <Box boxMain>
        <S.Container>
          <S.GridArea>
            {loading ? (
              <SingleEntityPageSkeleton />
            ) : (
              <>
                <S.MainContent>
                  {contact.id && (
                    <ActivitiesTemplate
                      context="contact"
                      contextId={contact.id}
                      phoneNumbers={phoneNumbers}
                      emails={emails}
                    />
                  )}

                  <Spacer height={16} />
                </S.MainContent>

                <S.RightContent>
                  {contact && <ContactCard contact={contact} />}
                  {contact?.products && (
                    <ProductCard
                      products={contact.products}
                      callback={callback}
                    />
                  )}
                  {contact.tags && <TagsCard tags={contact.tags} />}

                  {contact.company && <CompanyCard company={contact.company} />}
                  <Spacer height={64} />
                </S.RightContent>
              </>
            )}
          </S.GridArea>
        </S.Container>
      </Box>
      {contact && (
        <EditContactDrawer
          contact={contact}
          isDrawerOpen={editContactOpen}
          setDrawerOpen={setEditContact}
          callback={callback}
        />
      )}
      {contact && (
        <AddDealDrawer
          isDrawerOpen={addDealDrawer}
          setDrawerOpen={setAddDealDrawer}
          contact={contact}
          callback={callback}
        />
      )}
      <Dialog
        modal
        title={t('contact.attention')}
        open={dialogOpen}
        onOpenChange={() => setDialogOpen(false)}
        content={
          <Box flexDirection="column" gap={32}>
            <Typography noWrap variant="body">
              {t('contact.deleteConfirmation')}
            </Typography>

            <Box gap={8}>
              <Button
                loading={deleteLoading}
                onClick={() => deleteContact(contact.id)}
                variant="primary"
                text={t('contact.confirm')}
              />
              <Button
                onClick={() => pressEscKey()}
                variant="outline"
                text={t('contact.cancel')}
              />
            </Box>
          </Box>
        }
      />
      {contact.tags && (
        <AttachTagsDialog
          callback={callback}
          contact={contact}
          tags={contact.tags}
        />
      )}
    </SingleContactContext.Provider>
  );
};

export default SingleContactPage;
