import React, { ReactElement } from 'react';

export const Workflows = (): ReactElement => {
  return (
    <iframe
      src="https://taskflown8n.purplefield-f4c4f3e1.eastus.azurecontainerapps.io/workflows"
      width="100%"
      height="800"
    ></iframe>
  );
};
