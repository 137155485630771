import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Tabs } from 'mino-ui';
import { IconColors } from 'presentation/shared/templates/ActivityCard/actions';
import ManageModal from './ManageModal';
import DeleteDialog from './DeleteDialog';
import { CustomFieldsDataTable } from './datatable';
import { ICustomField } from 'domain/entity/ICustomField';
import { makeListCustomFields } from 'main/factories/usecases/custom_field/remote-list-custom-fields-factory';
import { useTranslation } from 'react-i18next';

const CustomFields = (): ReactElement => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [contentPayload, setContentPayload] = useState<ICustomField>();

  const [idToDelete, setIdToDelete] = useState<string>('');

  const [customFields, setCustomFields] = useState<ICustomField[]>([]);

  const [parentType, setParentType] =
    useState<ICustomField['parent_type']>('deal');

  async function fetchCustomFields(parentType: ICustomField['parent_type']) {
    try {
      const data = await makeListCustomFields(parentType).execute();

      setCustomFields(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchCustomFields(parentType);
  }, [parentType]);

  return (
    <Box flexDirection="column" gap={16}>
      <Box justifyContent="space-between">
        <Button
          icon="AddCircle20Regular"
          iconColor={IconColors.white}
          text={t('customFields.newField')}
          onClick={() => setModalOpen(true)}
        />
      </Box>

      <Box>
        <Tabs
          onTabChange={(tab) => {
            if (tab) setParentType(tab as ICustomField['parent_type']);
          }}
          trigger={[
            {
              id: 'deal',
              text: t('customFields.deals'),
              icon: 'Briefcase20Regular',
            },
            {
              id: 'contact',
              text: t('customFields.contacts'),
              icon: 'BookContacts20Regular',
            },
            {
              id: 'company',
              text: t('customFields.companies'),
              icon: 'BuildingMultiple20Regular',
            },
          ]}
          content={[
            {
              component: (
                <CustomFieldsDataTable
                  setIdToDelete={setIdToDelete}
                  setPayload={setContentPayload}
                  setModalOpen={setModalOpen}
                  customFields={customFields}
                />
              ),
              id: 'deal',
            },
            {
              component: (
                <CustomFieldsDataTable
                  setModalOpen={setModalOpen}
                  setIdToDelete={setIdToDelete}
                  setPayload={setContentPayload}
                  customFields={customFields}
                />
              ),
              id: 'contact',
            },
            {
              component: (
                <CustomFieldsDataTable
                  setModalOpen={setModalOpen}
                  setIdToDelete={setIdToDelete}
                  setPayload={setContentPayload}
                  customFields={customFields}
                />
              ),
              id: 'company',
            },
          ]}
        />
      </Box>

      <ManageModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        setUpdatePayload={setContentPayload}
        updatePayload={contentPayload}
        callback={() => {
          setContentPayload(undefined);
          fetchCustomFields(parentType);
        }}
      />

      {idToDelete ? (
        <DeleteDialog
          id={idToDelete}
          isOpen={idToDelete ? true : false}
          setOpen={(v) => !v && setIdToDelete('')}
          callback={() => {
            setIdToDelete('');
            fetchCustomFields(parentType);
          }}
        />
      ) : null}
    </Box>
  );
};

export default CustomFields;
