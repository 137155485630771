import React, { ReactElement, useContext } from 'react';
import { Box, Drawer, Select } from 'mino-ui';
import { useForm } from 'react-hook-form';
import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';
import FilterIcon from './icons/filterDrawer.svg';
import ActivityListContext from './context/ActivityListContext';
import { ActivityStatus, ActivityType } from 'domain/entity/IActivity';
import { useTranslation } from 'react-i18next';

interface FilterDrawerProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  fetchData: (params: ActivityFilterParams) => void;
}

const DrawerFilterActivity = (props: FilterDrawerProps): ReactElement => {
  const { isOpen, setIsOpen, fetchData } = props;
  const { setFilter, filter } = useContext(ActivityListContext);
  const { t } = useTranslation();

  function handleFilter(data: ActivityFilterParams): void {
    setFilter(data);
    fetchData(data);
    setIsOpen(false);
  }

  function resetFilter() {
    reset();
    fetchData({});
    setIsOpen(false);
  }

  const { control, handleSubmit, reset } = useForm<ActivityFilterParams>({
    defaultValues: {
      type: undefined,
      status: ActivityStatus.SCHEDULED,
    },
  });

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t('activities.filters')}
      icon={FilterIcon}
      secondarySubmitText={t('activities.clear')}
      secondarySubmitAction={() => {
        resetFilter();
      }}
      submitText={t('activities.filter')}
      onSubmit={handleSubmit(handleFilter)}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <Box flexDirection="column" gap={16}>
          <Select
            fullWidth
            label="Tipo"
            control={control}
            name="type"
            defaultValue=""
            options={[
              {
                value: '',
                label: t('activities.all'),
              },
              {
                value: ActivityType.CALL,
                label: t('activities.call'),
              },
              {
                value: ActivityType.MESSAGE,
                label: t('activities.message'),
              },
              {
                value: ActivityType.EMAIL,
                label: t('activities.email'),
              },
              {
                value: ActivityType.PROPOSAL,
                label: t('activities.proposal'),
              },
              {
                value: ActivityType.MEETING,
                label: t('activities.meeting'),
              },
              {
                value: ActivityType.NOTE,
                label: t('activities.note'),
              },
            ]}
          />
          <Select
            fullWidth
            label="Status"
            defaultValue={filter.status?.toString()}
            control={control}
            name="status"
            options={[
              {
                value: '',
                label: t('activities.all'),
              },
              {
                value: ActivityStatus.OPEN.toString(),
                label: t('activities.pending'),
              },
              {
                value: ActivityStatus.SCHEDULED.toString(),
                label: t('activities.scheduled'),
              },
              {
                value: ActivityStatus.FINISHED.toString(),
                label: t('activities.finished'),
              },
            ]}
          />
        </Box>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};

export default DrawerFilterActivity;
