import React, { ReactElement, useContext } from 'react';
import { Box } from 'mino-ui';
import { hasPermission } from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import CustomTabs from './components/customTabs/component';
import { useTranslation } from 'react-i18next';
export type TriggerProps = {
  id: string;
  text: string;
  icon: string;
};

export type ConfigTypeProps = {
  id: string;
  text: string;
  icon: string;
  triggers: TriggerProps[];
};

type ConfigPages =
  | 'general'
  | 'comercial'
  | 'admin'
  | 'integrations'
  | 'integrations/:id';

const ConfigPage = (): ReactElement => {
  const { t } = useTranslation();
  const { session } = useContext(SessionContext);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultActive = urlParams.get('active');

  const UsersConfigs: TriggerProps[] = [];
  const ComercialConfigsTriggers: TriggerProps[] = [];
  const IntegrationsConfigsTriggers: TriggerProps[] = [];

  const configs: ConfigTypeProps[] = [
    {
      id: 'general',
      text: t('config.users'),
      icon: 'PeopleSettings',
      triggers: UsersConfigs,
    },
    {
      id: 'comercial',
      text: t('config.commercial'),
      icon: 'ShoppingBag20Regular',
      triggers: ComercialConfigsTriggers,
    },
    {
      id: 'integrations',
      text: t('config.integrations'),
      icon: 'Connected20Regular',
      triggers: IntegrationsConfigsTriggers,
    },
  ];

  if (hasPermission(session.permissions, 'users', 'write')) {
    UsersConfigs.push({
      id: 'users',
      text: t('config.users'),
      icon: 'Person20Regular',
    });
  }

  if (hasPermission(session.permissions, 'roles', 'write')) {
    UsersConfigs.push({
      id: 'groups',
      text: t('config.groups'),
      icon: 'People',
    });
  }

  if (hasPermission(session.permissions, 'teams', 'write')) {
    UsersConfigs.push({
      id: 'teams',
      text: t('config.teams'),
      icon: 'PeopleTeam',
    });
  }

  if (hasPermission(session.permissions, 'products', 'write')) {
    ComercialConfigsTriggers.push({
      id: 'services',
      text: t('config.products'),
      icon: 'Box20Regular',
    });
  }

  if (hasPermission(session.permissions, 'funnels', 'write')) {
    ComercialConfigsTriggers.push({
      id: 'funnels',
      text: t('config.funnels'),
      icon: 'ColumnTriple20Regular',
    });
  }

  if (hasPermission(session.permissions, 'channels', 'write')) {
    ComercialConfigsTriggers.push({
      id: 'channels',
      text: t('config.channels'),
      icon: 'Channel20Regular',
    });
  }

  if (session && session.is_admin) {
    ComercialConfigsTriggers.push({
      icon: 'TextBulletListAdd20Regular',
      id: 'fields',
      text: t('config.customFields'),
    });
  }

  if (hasPermission(session.permissions, 'tags', 'write')) {
    ComercialConfigsTriggers.push({
      id: 'tags',
      text: t('config.tags'),
      icon: 'Tag',
    });
  }

  if (hasPermission(session.permissions, 'loss-reasons', 'write')) {
    ComercialConfigsTriggers.push({
      id: 'losses',
      text: t('config.lossReasons'),
      icon: 'Thumb',
    });
  }
  if (hasPermission(session.permissions, 'shared-links', 'write')) {
    ComercialConfigsTriggers.push({
      id: 'sharedLinks',
      text: t('config.sharedLinks'),
      icon: 'Pin20Regular',
    });
  }

  if (session.is_admin) {
    {
      ComercialConfigsTriggers.push({
        id: 'ImportBatch',
        text: t('config.imports'),
        icon: 'Archive20Regular',
      });
    }
  }
  if (hasPermission(session.permissions, 'integrations', 'write')) {
    IntegrationsConfigsTriggers.push({
      id: 'appStore',
      text: t('config.appStore'),
      icon: 'Apps20Regular',
    });
  }

  return (
    <Box boxMain gap={16} alignItems="center">
      <Box style={{ maxWidth: 1200, width: '100%', display: 'block' }}>
        {configs.length > 0 ? (
          <CustomTabs
            defaultActive={
              defaultActive ? (defaultActive as ConfigPages) : 'general'
            }
            configs={configs}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default ConfigPage;
