import React, { ReactElement, useContext } from 'react';
import * as S from './styles';
import { Drawer, Collapse, Spacer, Input, Select } from 'mino-ui';
import CompanyContext from '../../contexts/CompanyContext';
import { useForm } from 'react-hook-form';
import { CompanyRequestParams } from 'domain/usecases/company/list-companies-use-case';
import FilterIcon from '../icons/filter.svg';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { useTranslation } from 'react-i18next';

const DrawerFilterCompany = (): ReactElement => {
  const { isFilterOpen, setFilterOpen, setFilter, fetchCompanies } =
    useContext(CompanyContext);
  const { t } = useTranslation();

  function handleFilter(data: CompanyRequestParams): void {
    fetchCompanies(data);
    setFilter(data);
    setFilterOpen(false);
  }

  function resetFilter() {
    reset();
    fetchCompanies({});
    setFilterOpen(false);
  }

  const { register, handleSubmit, reset, control } =
    useForm<CompanyRequestParams>({
      defaultValues: {
        state: '',
      },
    });

  const { custom_fields } = useCustomFields('company');

  return (
    <Drawer
      width={300}
      isOpen={isFilterOpen}
      onClose={() => setFilterOpen(false)}
      title={t('company.filters')}
      icon={FilterIcon}
      submitText={t('company.filter')}
      onSubmit={handleSubmit(handleFilter)}
      secondarySubmitText={t('company.clear')}
      secondarySubmitAction={() => {
        resetFilter();
      }}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <S.DrawerForm>
          <Collapse
            defaultOpen={true}
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('company.name')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Input
                autoFocus
                label={t('company.name')}
                name="search"
                placeholder={t('company.name')}
                register={register}
              />
            }
          />
          <Collapse
            defaultOpen={false}
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('company.email')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Input
                label={t('company.email')}
                name="email"
                placeholder={t('company.email')}
                register={register}
              />
            }
          />
          <Collapse
            defaultOpen={false}
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('company.cnpj')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Input
                label={t('company.cnpj')}
                name="cnpj"
                placeholder={t('company.cnpj')}
                register={register}
              />
            }
          />
          <Collapse
            defaultOpen={false}
            hasArrow
            trigger={
              <S.WrapTrigger>
                <S.TriggerText>{t('company.address')}</S.TriggerText>
              </S.WrapTrigger>
            }
            content={
              <Select
                fullWidth
                name="state"
                control={control}
                placeholder={t('company.selectState')}
                label={t('company.state')}
                options={BrazilianStates.map((es) => ({
                  label: es.nome,
                  value: es.sigla,
                }))}
              />
            }
          />

          {custom_fields?.map((field) => (
            <Collapse
              key={field.id}
              hasArrow
              trigger={
                <S.WrapTrigger>
                  <S.TriggerText>{field.name}</S.TriggerText>
                </S.WrapTrigger>
              }
              content={
                <>
                  <Input
                    label={field.name}
                    full
                    register={register}
                    name={`cf_${field.id}`}
                    placeholder={`${field.name}`}
                  />
                </>
              }
            />
          ))}

          <Spacer height={16} />
        </S.DrawerForm>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};
export default DrawerFilterCompany;
