import React, { ReactElement } from 'react';
import { Card, Tabs } from 'mino-ui';
import CallTab from './atoms/CallTab';
import MessageTab from './atoms/MessageTab';
import PurposeTab from './atoms/PurposeTab';
import EmailTab from './atoms/EmailTab';
import MeetingTab from './atoms/MeetingTab';
import NotesTab from './atoms/NotesTab';
import * as T from './types';
import { useTranslation } from 'react-i18next';
const ActivityTabs = (props: T.ActivityTabsProps): ReactElement => {
  const { context } = props;
  const { t } = useTranslation();

  return (
    <Card aria-label={context}>
      <Tabs
        hiddenTriggerTextOnMobile
        triggerGap={0}
        trigger={[
          {
            id: 'call',
            text: t('activities.call'),
            icon: 'Call20Regular',
          },
          {
            id: 'email',
            text: t('activities.email'),
            icon: 'Mail20Regular',
          },
          {
            id: 'message',
            text: t('activities.message'),
            icon: 'Chat20Regular',
          },
          {
            id: 'purpose',
            text: t('activities.proposal'),
            icon: 'Handshake20Regular',
          },
          {
            id: 'meeting',
            text: t('activities.meeting'),
            icon: 'BuildingPeople20Regular',
          },
          {
            id: 'notes',
            text: t('activities.note'),
            icon: 'Notepad20Regular',
          },
        ]}
        content={[
          {
            id: 'call',
            component: <CallTab />,
          },
          {
            id: 'message',
            component: <MessageTab />,
          },
          {
            id: 'purpose',
            component: <PurposeTab />,
          },
          {
            id: 'email',
            component: <EmailTab />,
          },
          {
            id: 'meeting',
            component: <MeetingTab />,
          },
          {
            id: 'notes',
            component: <NotesTab />,
          },
        ]}
      />
    </Card>
  );
};
export default ActivityTabs;
