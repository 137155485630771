import React from 'react';
import { Icon, Typography } from 'mino-ui';
import { IFile } from 'domain/entity/IFile';
import { FilesPopOverList } from '../../ActivityCard/styles';
import { removeDateFromFileName } from '../../Activitites/helpers/format-file-name';
import { downloadFile } from '../../Activitites/helpers/download-file';
import { useTranslation } from 'react-i18next';
type UploadFileProps = {
  files: IFile[];
};

export const ActivityFileList = (props: UploadFileProps) => {
  const { files } = props;
  const { t } = useTranslation();

  return (
    <FilesPopOverList>
      <Typography variant="body" fontSize={14} weight="bold" color="gray">
        {t('activities.attachments')}
      </Typography>
      {files?.map((file) => (
        <li key={file.id}>
          <span>{removeDateFromFileName(file.name)}</span>

          <button onClick={() => downloadFile(file.url)} type="button">
            <Icon iconName="ArrowDownload16Regular" />
          </button>
        </li>
      ))}
    </FilesPopOverList>
  );
};
