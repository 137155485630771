import React, { ReactElement, useContext, useState } from 'react';
import * as S from '../ActionButton/styles';
import * as T from '../ActionButton/types';
import Like from '../../icons/like.svg';
import Unlike from '../../icons/unlike.svg';
import Edit from '../../icons/edit.svg';
import { ReactSVG } from 'react-svg';
import ChangeResultTemplate from 'presentation/pages/Deal/shared/ChangeResult';
import { useParams } from 'react-router';
import { SingleDealContext } from 'presentation/pages/Deal/contexts/SingleDealContext';
import { useTranslation } from 'react-i18next';
const ActionButton = (props: T.ActionButtonProps): ReactElement => {
  const { fetchDeal } = useContext(SingleDealContext);
  const { type } = props;
  const [open, setOpen] = useState(false);

  const { id = '' } = useParams();
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.ActionButton onClick={() => setOpen(true)} type="button" action={type}>
        <S.IconWrapper action={type}>
          <ReactSVG
            src={type === 'sell' ? Like : type === 'loss' ? Unlike : Edit}
          />
        </S.IconWrapper>

        <span>
          {type === 'sell'
            ? t('deal.sell')
            : type === 'loss'
            ? t('deal.loss')
            : t('deal.reopen')}
        </span>
      </S.ActionButton>

      <ChangeResultTemplate
        dealId={id}
        onOpenChange={(v) => setOpen(v)}
        open={open}
        type={type}
        callback={fetchDeal}
      />
    </S.Container>
  );
};

export default ActionButton;
