/**
 * Formats a message by replacing placeholders {0}, {1}, etc. with the provided values
 * @param message The message with placeholders
 * @param args The values to replace the placeholders with
 * @returns The formatted message
 */
export const formatMessage = (message: string, ...args: any[]): string => {
  return message.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== 'undefined' ? args[index] : match;
  });
};
