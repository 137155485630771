import React, { ReactElement, useState } from 'react';
import * as S from './style';
import { ContactRequestDTO } from 'domain/dtos/contact/contact-request-dto';
import {
  makeRemoteSaveContact,
  makeUpdateDealUseCase,
} from 'main/factories/usecases';
import { useForm } from 'react-hook-form';
import { Dialog, Input, Select, Spacer } from 'mino-ui';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { useChannels } from 'main/hooks/useChannel';
import { useCompanies } from 'main/hooks/useCompanies';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useNavigate } from 'react-router';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { useTranslation } from 'react-i18next';
interface AddContactDialogProps {
  isDialogOpen: boolean;
  setDialogOpen: (val: boolean) => void;
  context: 'contact' | 'deal' | 'company';
  singleId: string;
  callback?: () => void;
  trigger?: ReactElement;
}

const AddContactDialog = (props: AddContactDialogProps): ReactElement => {
  const { isDialogOpen, setDialogOpen, callback, singleId, context, trigger } =
    props;
  const [load, setLoad] = useState(false);
  const [companySearch, setCompanySearch] = useState('');

  const { channels } = useChannels();
  const { companies, fetchCompanies } = useCompanies();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<ContactRequestDTO>();

  async function createContact(data: ContactRequestDTO) {
    try {
      setLoad(true);

      if (context === 'contact') {
        const custom_fields_inputs: ContactRequestDTO['custom_fields'] = {};

        for (const field of custom_fields) {
          if (data[`cf_${field.id}`]) {
            custom_fields_inputs['cf_' + field.id] = data[`cf_${field.id}`];
          }
        }

        const { id } = await makeRemoteSaveContact().execute({
          ...data,
          custom_fields: custom_fields_inputs,
        });

        if (id) {
          navigate(`/contacts/${id}`);
        }
      }

      if (context === 'deal') {
        const { id } = await makeRemoteSaveContact().execute(data);
        await makeUpdateDealUseCase(singleId).execute({
          attach_contacts: [id],
        });
      }

      if (context === 'company') {
        await makeRemoteSaveContact().execute({
          ...data,
          company_id: singleId,
        });
      }

      reset();
      callback?.();
      setDialogOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  useDebouncedEffect(
    () => {
      if (companySearch.length > 0) {
        fetchCompanies(companySearch);
      }
    },
    [companySearch],
    300
  );

  const { custom_fields } = useCustomFields('contact');

  return (
    <Dialog
      maxWidth={520}
      title={t('contact.add')}
      loading={load}
      open={isDialogOpen}
      onOpenChange={(state) => {
        !state && reset?.();
        setDialogOpen?.(state);
      }}
      trigger={trigger}
      onSubmit={handleSubmit(createContact)}
      content={
        <form onSubmit={handleSubmit(createContact)}>
          <S.Subtitle>{t('contact.personalInformation')}</S.Subtitle>

          <S.WrapFields>
            <Input
              autoFocus
              full
              type="text"
              name="name"
              label={t('contact.name')}
              placeholder={t('contact.name')}
              register={register}
              errors={errors}
              validations={{
                required: true,
              }}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="email"
              label={t('contact.email')}
              placeholder={t('contact.email')}
              register={register}
              errors={errors}
              validations={{
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              }}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="phone"
              label={t('contact.phone')}
              placeholder={t('contact.phone')}
              register={register}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="cpf"
              label={t('contact.cpf')}
              placeholder={t('contact.cpf')}
              register={register}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="description"
              label={t('contact.description')}
              placeholder={t('contact.description')}
              register={register}
            />
          </S.WrapFields>

          <S.Divider />
          <S.Subtitle>{t('contact.addressInformation')}</S.Subtitle>

          <S.WrapFields>
            <Select
              fullWidth
              name="state"
              onInputChange={(v) => setCompanySearch(v)}
              control={control}
              placeholder={t('contact.state')}
              label={t('contact.state')}
              options={BrazilianStates.map((es) => ({
                label: es.nome,
                value: es.sigla,
              }))}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="city"
              label={t('contact.city')}
              placeholder={t('contact.city')}
              register={register}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="address"
              label={t('contact.address')}
              placeholder={t('contact.address')}
              register={register}
            />
            <Spacer height={16} />
            <Input
              full
              type="text"
              name="zip"
              label={t('contact.zip')}
              placeholder={t('contact.zip')}
              register={register}
            />
          </S.WrapFields>

          <S.Divider />

          <S.Subtitle>{t('contact.otherCategory')}</S.Subtitle>

          <S.WrapFields>
            {context !== 'company' && (
              <Select
                fullWidth
                name="company_id"
                onInputChange={(v) => setCompanySearch(v)}
                control={control}
                placeholder={t('contact.selectCompany')}
                label={t('contact.company')}
                options={companies.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />
            )}
            <Spacer height={16} />
            <Select
              fullWidth
              name="channel_id"
              onInputChange={(v) => setCompanySearch(v)}
              control={control}
              placeholder={t('contact.selectSource')}
              label={t('contact.source')}
              options={channels.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
            />

            {custom_fields.map((field) => (
              <>
                <Spacer height={16} />
                <Input
                  full
                  key={field.id}
                  register={register}
                  type={'text'}
                  placeholder={field.name}
                  name={`cf_${field.id}`}
                  label={field.name}
                  errors={errors}
                />
              </>
            ))}
          </S.WrapFields>

          <input type="submit" hidden />
        </form>
      }
    />
  );
};

export default AddContactDialog;
