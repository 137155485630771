import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Box, Drawer, Icon, Input, Label, Select, Tooltip } from 'mino-ui';
import { useGroups } from 'main/hooks/useGroups';
import { makeSaveUserUseCase } from 'main/factories/usecases/user/save-users-factory';
import { SaveUserRequestPayload } from 'domain/usecases/user/save-user-use-case';
import NewUser from './icons/newUser.svg';
import { useForm } from 'react-hook-form';
import { useTeams } from 'main/hooks/useTeams';
import { UserTabContext } from '../context/UserTabContext';
import * as S from './styles';
import { useTranslation } from 'react-i18next';

interface ManageDrawerProps {
  callback: () => void;
  updatePayload?: SaveUserRequestPayload;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setUpdatePayload: (value?: SaveUserRequestPayload) => void;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { t } = useTranslation();
  const { callback, isOpen, setIsOpen, updatePayload, setUpdatePayload } =
    props;

  const { setInvited } = useContext(UserTabContext);

  const updateMode = updatePayload !== undefined ? true : false;
  const [loading, setLoading] = useState(false);

  const { groups, loading: loadingGroups } = useGroups();
  const { teams, loading: loadingTeams } = useTeams();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<SaveUserRequestPayload>({
    defaultValues: {
      name: updatePayload?.name,
      email: updatePayload?.email,
      role_id: updatePayload?.role_id,
      team_id: updatePayload?.team_id,
      automation: updatePayload?.automation,
      active: updatePayload?.active,
      ramal: updatePayload?.ramal,
      is_supervisor: updatePayload?.is_supervisor || false,
    },
  });

  async function saveUser(data: SaveUserRequestPayload) {
    try {
      setLoading(true);

      await makeSaveUserUseCase(
        updateMode ? updatePayload?.id : undefined
      ).execute(
        {
          ...data,
          active: (data.active as unknown as string) === '1' ? true : false,
          is_supervisor:
            (data.is_supervisor as unknown as string) === '1' ? true : false,
          automation:
            (data.automation as unknown as string) === '1' ? true : false,
        },
        updateMode ? 'update' : 'create'
      );
      reset();
      callback();
      setUpdatePayload(undefined);
      setIsOpen(false);
      setInvited('1');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset, updatePayload]);

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => {
        reset();
        setIsOpen(false);
        setUpdatePayload(undefined);
      }}
      title={updateMode ? t('users.updateUser') : t('users.newUser')}
      icon={NewUser}
      submitText={t('users.save')}
      onSubmit={handleSubmit(saveUser)}
      secondarySubmitAction={() => {
        reset();
        setIsOpen(false);
        setUpdatePayload(undefined);
      }}
      secondarySubmitText={t('users.cancel')}
      loadingSubmit={loading}
    >
      <form autoComplete="off" onSubmit={handleSubmit(saveUser)}>
        <Box gap={8} flexDirection="column">
          <Select
            name="active"
            label={t('users.active')}
            onChange={(v) => {
              setValue('active', v?.value === '1' ? true : false);
            }}
            defaultValue={
              updatePayload?.active === undefined
                ? '1'
                : updatePayload?.active == true
                ? '1'
                : '0'
            }
            options={[
              {
                value: '1',
                label: 'Sim',
              },
              {
                value: '0',
                label: 'Não',
              },
            ]}
            placeholder="Selecione"
            fullWidth
            required
          />

          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            label={t('users.name')}
            placeholder="Ex: João Carlos"
            name="name"
            validations={{
              required: true,
              minLength: 3,
            }}
          />
          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            label={t('users.email')}
            placeholder="Ex: example@admin.com"
            name="email"
            validations={{
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              required: true,
            }}
          />

          {updatePayload?.id ? (
            <Input
              autoComplete="off"
              register={register}
              errors={errors}
              label={t('users.password')}
              type="password"
              name="password"
              validations={{
                minLength: 6,
              }}
            />
          ) : null}

          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            label={t('users.ramal')}
            placeholder="Ex: 400"
            name="ramal"
          />

          {!loadingGroups && (
            <Select
              onChange={(v) => {
                setValue('role_id', v?.value);
              }}
              defaultValue={updatePayload?.role_id}
              options={groups.map((g) => ({
                value: g.id,
                label: g.name,
              }))}
              placeholder={t('users.selectGroup')}
              fullWidth
              label={t('users.group')}
              required
              name="group_id"
            />
          )}

          <Box flexDirection="column">
            <S.SelectLabelWrap>
              <Label text={t('users.supervisor')} removeMargin />
              <Tooltip
                content={t('users.supervisorTooltip')}
                trigger={
                  <div>
                    <Icon iconName="QuestionCircle20Regular" />
                  </div>
                }
                align="center"
                side="top"
              />
            </S.SelectLabelWrap>
            <Select
              onChange={(v) => {
                setValue('is_supervisor', v?.value === '1' ? true : false);
              }}
              options={[
                {
                  value: '1',
                  label: 'Sim',
                },
                {
                  value: '0',
                  label: 'Não',
                },
              ]}
              placeholder="Selecione"
              fullWidth
              required
              name="is_supervisor"
              defaultValue={
                updatePayload?.is_supervisor === undefined
                  ? ''
                  : updatePayload?.is_supervisor == true
                  ? '1'
                  : '0'
              }
            />
          </Box>

          <Select
            onChange={(v) => {
              setValue('automation', v?.value === '1' ? true : false);
            }}
            defaultValue={updatePayload?.automation == true ? '1' : '0'}
            options={[
              {
                value: '1',
                label: 'Sim',
              },
              {
                value: '0',
                label: 'Não',
              },
            ]}
            placeholder="Selecione"
            fullWidth
            name="automation"
            label={t('users.receivesLeads')}
          />

          {!loadingTeams && (
            <Select
              onChange={(v) => {
                setValue('team_id', v?.value);
              }}
              defaultValue={updatePayload?.team_id}
              name="team_id"
              options={teams.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              placeholder="Selecione"
              fullWidth
              label={t('users.team')}
            />
          )}

          <input hidden type="submit" />
        </Box>
      </form>
    </Drawer>
  );
};
