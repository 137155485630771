import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Drawer, Avatar, Select, Spacer, Typography } from 'mino-ui';
import { makeUpdateDealUseCase } from 'main/factories/usecases';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import CloseIcon from './icons/close.svg';
import CompanyIcon from './icons/business.svg';
import { ICompany } from 'domain/entity/ICompany';
import { makeListCompaniesUseCase } from 'main/factories/usecases/company/list-companies-factory';
import { useTranslation } from 'react-i18next';

type CompanyDrawerProps = {
  companies: ICompany[];
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  callback: () => void;
};
export const CompanyDrawer = (props: CompanyDrawerProps): ReactElement => {
  const { companies: companiesProps, isOpen, setOpen, callback } = props;
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState<ICompany[]>([]);

  const [companies, setCompanies] = useState(
    companiesProps.map((c) => ({
      id: c.id,
      name: c.name,
    }))
  );

  const [search, setSearch] = useState('');

  const fetchCompanies = useCallback(async () => {
    try {
      setLoadingCompanies(true);
      const { data } = await makeListCompaniesUseCase().execute({
        search,
      });
      setCompaniesOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCompanies(false);
    }
  }, [search, setCompaniesOptions]);

  const [attach_companies, setAttachCompanies] = useState<string[]>([]);
  const [detach_companies, setDetachCompanies] = useState<string[]>([]);

  const attachCompanies = useCallback(
    (id: string, name: string) => {
      if (
        attach_companies.includes(id) ||
        companies.map((c) => c.id).includes(id)
      ) {
        return;
      }

      setAttachCompanies([...attach_companies, id]);

      const newDetach = detach_companies.filter((a) => a !== id);
      setDetachCompanies(newDetach);

      const newState = companies.filter((c) => c.id !== id);
      setCompanies([...newState, { id, name }]);
    },
    [
      attach_companies,
      setCompanies,
      companies,
      setAttachCompanies,
      detach_companies,
      setDetachCompanies,
    ]
  );

  const detachCompanies = useCallback(
    (id: string) => {
      if (detach_companies.includes(id)) return;
      setDetachCompanies([...detach_companies, id]);

      const newAttach = attach_companies.filter((a) => a !== id);
      setAttachCompanies(newAttach);

      const newState = companies.filter((c) => c.id !== id);
      setCompanies(newState);
    },
    [
      setCompanies,
      companies,
      setCompanies,
      detach_companies,
      setDetachCompanies,
      attach_companies,
      setAttachCompanies,
    ]
  );

  const handleAttachCompanies = useCallback(async () => {
    try {
      await makeUpdateDealUseCase(id).execute({
        attach_companies: attach_companies,
        detach_companies: detach_companies,
      });

      fetchCompanies();
      setSearch('');
      callback();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  }, [fetchCompanies, setSearch, attach_companies, detach_companies]);

  useDebouncedEffect(fetchCompanies, [fetchCompanies], 200);

  const reset = useCallback(() => {
    setAttachCompanies([]);
    setDetachCompanies([]);
  }, [isOpen, setAttachCompanies, setDetachCompanies]);

  useEffect(reset, [reset]);

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title={t('deal.linkCompany')}
      icon={CompanyIcon}
      submitText={t('deal.save')}
      onSubmit={handleAttachCompanies}
      secondarySubmitText={t('deal.cancel')}
      secondarySubmitAction={() => {
        setOpen(false);
      }}
    >
      <Box gap={16} flexDirection="column">
        <Select
          isLoading={loadingCompanies}
          onInputChange={(value) => {
            setSearch(value);
          }}
          label={t('deal.addCompany')}
          placeholder={t('deal.searchCompany')}
          options={companiesOptions
            .filter((c) => !companies.map((c) => c.id).includes(c.id))
            .map((company) => ({
              value: company.id,
              label: company.name,
            }))}
          onChange={(d) => d && attachCompanies(d.value, d.label || '')}
        />

        <Spacer height={16} />

        <Box flexDirection="column" justifyContent="center" gap={16}>
          <Typography variant="body" weight="bold">
            {t('deal.linkedCompanies')}
          </Typography>

          {companies.map((Company) => (
            <Box key={Company.id} alignItems="center" gap={8}>
              <Box
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                gap={8}
              >
                <Box gap={8} alignItems="center">
                  <Avatar name={Company.name} />
                  <Typography color="gray" variant="body" weight="bold">
                    {Company.name}
                  </Typography>
                </Box>

                <ReactSVG
                  onClick={() => detachCompanies(Company.id)}
                  src={CloseIcon}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};
