import React, { ReactElement, useState } from 'react';
import { ContactRequestDTO } from 'domain/dtos/contact/contact-request-dto';
import {
  makeSaveCompanyUseCase,
  makeUpdateDealUseCase,
} from 'main/factories/usecases';
import { useForm } from 'react-hook-form';
import { Dialog, Input, Select, Spacer } from 'mino-ui';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { useNavigate } from 'react-router';
import { makeUpdateContactUseCase } from 'main/factories/usecases/contact/update-contact-factory';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { useTranslation } from 'react-i18next';

interface AddCompanyDialogProps {
  isDialogOpen: boolean;
  setDialogOpen: (val: boolean) => void;
  context: 'contact' | 'deal' | 'company';
  singleId: string;
  callback?: () => void;
  trigger?: ReactElement;
}

const AddCompanyDialog = (props: AddCompanyDialogProps): ReactElement => {
  const { t } = useTranslation();
  const { isDialogOpen, setDialogOpen, callback, singleId, context, trigger } =
    props;
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<ContactRequestDTO>();

  async function createCompany(data: ContactRequestDTO) {
    try {
      setLoad(true);

      if (context === 'company') {
        const custom_fields_inputs: ContactRequestDTO['custom_fields'] = {};

        for (const field of custom_fields) {
          if (data[`cf_${field.id}`]) {
            custom_fields_inputs['cf_' + field.id] = data[`cf_${field.id}`];
          }
        }

        const { id } = await makeSaveCompanyUseCase().execute({
          ...data,
          custom_fields: custom_fields_inputs,
        });

        if (id) {
          navigate(`/companies/${id}`);
        }
      }

      if (context === 'deal') {
        const { id } = await makeSaveCompanyUseCase().execute(data);
        await makeUpdateDealUseCase(singleId).execute({
          attach_companies: [id],
        });
      }

      if (context === 'contact') {
        const { id } = await makeSaveCompanyUseCase().execute(data);
        await makeUpdateContactUseCase(singleId).execute({ company_id: id });
      }

      reset();
      callback?.();
      setDialogOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  const { custom_fields } = useCustomFields('company');

  return (
    <Dialog
      maxWidth={520}
      title={t('company.add')}
      loading={load}
      open={isDialogOpen}
      onOpenChange={(state) => {
        !state && reset?.();
        setDialogOpen?.(state);
      }}
      trigger={trigger}
      onSubmit={handleSubmit(createCompany)}
      content={
        <form onSubmit={handleSubmit(createCompany)}>
          <Input
            autoFocus
            type="text"
            full
            name="name"
            label={t('company.name')}
            placeholder={t('company.name')}
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Spacer height={16} />
          <Input
            full
            type="text"
            name="email"
            label={t('company.email')}
            placeholder={t('company.email')}
            register={register}
            errors={errors}
            validations={{
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            }}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="phone"
            label={t('company.phone')}
            placeholder={t('company.phone')}
            register={register}
            errors={errors}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="site"
            label={t('company.site')}
            placeholder={t('company.site')}
            register={register}
            errors={errors}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="cnpj"
            label={t('company.cnpj')}
            placeholder={t('company.cnpj')}
            register={register}
            errors={errors}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="address"
            label={t('company.address')}
            placeholder={t('company.address')}
            register={register}
            errors={errors}
          />

          <Spacer height={16} />

          <Input
            full
            type="text"
            name="city"
            label={t('company.city')}
            placeholder={t('company.city')}
            register={register}
            errors={errors}
          />

          <Spacer height={16} />

          <Select
            fullWidth
            name="state"
            control={control}
            placeholder={t('company.selectState')}
            label={t('company.state')}
            options={BrazilianStates.map((es) => ({
              label: es.nome,
              value: es.sigla,
            }))}
          />

          <Spacer height={16} />

          <Input
            full
            type="text"
            name="description"
            label={t('company.description')}
            placeholder={t('company.description')}
            register={register}
            errors={errors}
          />

          {custom_fields.map((field) => (
            <>
              <Spacer height={16} />
              <Input
                full
                key={field.id}
                register={register}
                type={'text'}
                placeholder={field.name}
                name={`cf_${field.id}`}
                label={field.name}
                errors={errors}
              />
            </>
          ))}
          <Spacer height={16} />

          <input type="submit" hidden />
        </form>
      }
    />
  );
};

export default AddCompanyDialog;
