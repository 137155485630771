import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Drawer, Input, Select, Spacer, Datepicker } from 'mino-ui';
import * as S from './styles';
import { IDeal } from 'domain/entity/IDeal';
import { UpdateDealRequest } from 'domain/usecases/deal/update-deal-use-case';
import { makeUpdateDealUseCase } from 'main/factories/usecases';
import Icon from '../icons/case.svg';
import { useForm } from 'react-hook-form';
import { useChannels } from 'main/hooks/useChannel';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { SingleDealContext } from '../../contexts/SingleDealContext';
import { useTranslation } from 'react-i18next';

interface EditDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  deal: IDeal;
  callback: () => void;
  salesValue: number;
}
const EditDealDrawer = (props: EditDrawerProps): ReactElement => {
  const { deal, isDrawerOpen, setDrawerOpen, callback, salesValue } = props;
  const [load, setLoad] = useState(false);
  const { setTransferDataDialog, all_custom_fields } =
    useContext(SingleDealContext);
  const { t } = useTranslation();
  const { channels } = useChannels();

  const [userSearch, setUserSearch] = useState('');
  const { users, fetchUsers } = useUsers();

  async function updateDeal(data: UpdateDealRequest) {
    try {
      setLoad(true);

      const custom_fields: UpdateDealRequest['custom_fields'] = {};

      for (const field of all_custom_fields) {
        custom_fields['cf_' + field.id] = data[`cf_${field.id}`] || '';
      }

      await makeUpdateDealUseCase(`${deal?.id}`).execute({
        ...data,
        custom_fields,
      });
      callback();
      setDrawerOpen(false);
      if (data.user_id != deal.user_id) {
        openDialogAfterRequest();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  const {
    register,
    formState: { errors },
    control,

    handleSubmit,
    setValue,
  } = useForm<UpdateDealRequest>();

  useEffect(() => {
    setValue('name', deal.name);
    setValue('value', deal.value);
    setValue('payment', deal.payment);
    setValue('channel_id', deal?.channel_id);
    setValue('closing_date', deal?.closing_date || undefined);
    setValue('user_id', deal?.user?.id);

    if (deal.custom_fields?.length) {
      deal.custom_fields.forEach((field) => {
        setValue(`cf_${field.custom_field_id}` as any, field.value);
      });
    }
  }, [deal, setValue]);

  useDebouncedEffect(
    () => {
      if (userSearch.length > 0) {
        fetchUsers(userSearch);
      }
    },
    [userSearch],
    300
  );

  function openDialogAfterRequest() {
    setTimeout(() => {
      setTransferDataDialog(true);
    }, 2000);
  }

  return (
    <Drawer
      width={300}
      isOpen={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      title={t('deal.editDeal')}
      icon={Icon}
      submitText={t('deal.save')}
      onSubmit={handleSubmit(updateDeal)}
      secondarySubmitText={t('deal.cancel')}
      secondarySubmitAction={() => {
        setDrawerOpen(false);
      }}
      loadingSubmit={load}
      loadingSubmitText={t('deal.saving')}
    >
      <form onSubmit={handleSubmit(updateDeal)}>
        <S.EditDrawerFormWrap>
          <Input
            autoFocus
            label={t('deal.description')}
            placeholder={t('deal.descriptionPlaceholder')}
            type="text"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />

          <Input
            label={t('deal.value')}
            placeholder={t('deal.valuePlaceholder')}
            type="number"
            name="value"
            register={register}
            disabled={salesValue > 0}
          />
          <Select
            fullWidth
            placeholder={t('deal.selectPayment')}
            label={t('deal.payment')}
            control={control}
            name="payment"
            options={[
              {
                label: t('deal.unique'),
                value: 'unique',
              },
              {
                label: t('deal.recurring'),
                value: 'recurrent',
              },
            ]}
          />
          <S.InputsWrap>
            <Select
              fullWidth
              placeholder={t('deal.selectOrigin')}
              label={t('deal.origin')}
              name="channel_id"
              control={control}
              defaultValue={deal.channel_id}
              options={channels.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
            />
          </S.InputsWrap>

          <S.InputsWrap>
            <Select
              fullWidth
              placeholder={t('deal.selectOwner')}
              label={t('deal.owner')}
              name="user_id"
              onInputChange={(v) => setUserSearch(v)}
              control={control}
              defaultValue={deal.user_id}
              options={[
                {
                  value: null,
                  label: t('deal.noOwner'),
                },
                ...users.map((u) => ({
                  label: u.name,
                  value: u.id,
                })),
              ]}
            />
          </S.InputsWrap>

          <S.InputsWrap>
            <Datepicker
              isClearable
              name="closing_date"
              control={control}
              label={t('deal.predict')}
              placeholder={t('deal.selectDate')}
            />
          </S.InputsWrap>

          {all_custom_fields?.map((field) => (
            <S.InputsWrap key={field.id}>
              <Input
                full
                label={field.name}
                placeholder={field.name}
                type="text"
                name={`cf_${field.id}`}
                register={register}
              />
            </S.InputsWrap>
          ))}

          <Spacer height={32} />
        </S.EditDrawerFormWrap>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};
export default EditDealDrawer;
