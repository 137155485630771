import { t } from '../i18n';

export const defaultInputValidationMessages = {
  required: () => t('required'),
  minLength: (min: number) => t('minLength', min),
  maxLength: (max: number) => t('maxLength', max),
  min: (min: number) => t('min', min),
  max: (max: number) => t('max', max),
  pattern: () => t('pattern'),
};
