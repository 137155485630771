import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  Box,
  Button,
  Input,
  Table,
  Typography,
  Dialog,
  Avatar,
  Dropdown,
} from 'mino-ui';
import AddIcon from './icons/add.svg';
import { IUser } from 'domain/entity/IUser';
import {
  makeDeleteUserUseCase,
  makeLoadUsersUseCase,
} from 'main/factories/usecases';
import Pagination from 'react-js-pagination';
import { ManageDrawer } from './ManageDrawer';
import { SaveUserRequestPayload } from 'domain/usecases/user/save-user-use-case';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { copyContent } from 'main/helpers';
import { makeToastify } from 'main/factories/notification';
import { useTranslation } from 'react-i18next';
const UsersConfig = (): ReactElement => {
  const {
    session: { id: currentUserId, subscription },
  } = useContext(SessionContext);
  const { t } = useTranslation();
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<SaveUserRequestPayload>();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [users, setUsers] = useState<IUser[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  async function fetchUsers(search: string, page: number) {
    try {
      const {
        data,
        page: activePage,
        total,
      } = await makeLoadUsersUseCase().execute({
        name: search,
        page: page,
      });

      setUsers(data);
      setTotal(total);
      setPage(activePage);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteUser() {
    if (deleteUserId === currentUserId) return;

    try {
      setDeleteLoading(true);
      await makeDeleteUserUseCase(deleteUserId).execute();

      fetchUsers('', 1);
      setSearch('');
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      setDeleteUserId('');
      pressEscKey();
    }
  }

  useEffect(() => {
    fetchUsers(search, page);
  }, [search, page]);

  function handlePageChange(pageNumber: number) {
    setPage(pageNumber);
  }

  function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
    setPage(1);
    setSearch(e.target.value);
  }
  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <Button
          onClick={() => {
            if ((subscription?.quantity || 0) - total <= 0) return;
            setManageOpen(true);
          }}
          icon={AddIcon}
          text={t('deal.add')}
          disabled={(subscription?.quantity || 0) - total <= 0}
        />

        <Input
          onChange={handleSearchChange}
          placeholder={t('deal.search')}
          name="search"
        />
      </Box>
      <Box
        style={{
          width: '100%',
          padding: 4,
          fontSize: 12,
          borderRadius: 2,
        }}
      >
        <Typography variant="body" fontSize={12} color="gray">
          <strong>{(subscription?.quantity || 0) - total}</strong>{' '}
          {t('users.available')}
        </Typography>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: t('user.name'),
          },
          {
            field: 'email',
            headerName: t('user.email'),
            hiddenOnMobile: true,
          },
          {
            field: 'leads',
            headerName: t('user.leads'),
            hiddenOnMobile: true,
          },
          {
            field: 'role',
            headerName: t('user.role'),
            hiddenOnMobile: true,
          },
          {
            field: 'team',
            headerName: t('user.team'),
            hiddenOnMobile: true,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={users.map((u) => ({
          id: u.id,
          name: (
            <Box gap={8} alignItems="center">
              {u.name || u.avatar ? (
                <Avatar name={u.name} image={u.avatar} />
              ) : (
                ''
              )}
              <Typography variant="body" fontSize={14} color="gray">
                {u.name}
              </Typography>
            </Box>
          ),
          role: u.roles?.[0]?.name,
          email: u.email,
          leads: u.automation ? 'Sim' : 'Não',
          team: u.team?.name,
          actions: (
            <Dropdown
              align="end"
              trigger="action-button"
              items={[
                { label: t('users.edit'), value: 'edit', icon: 'Pen20Regular' },
                {
                  label: t('users.delete'),
                  value: 'delete',
                  icon: 'Delete20Regular',
                  hidden: u.id === currentUserId || u.is_admin,
                },
              ].concat(
                u.invitation_link
                  ? [
                      {
                        label: t('users.invite'),
                        value: 'link',
                        icon: 'Link20Regular',
                      },
                    ]
                  : []
              )}
              onChange={(value) => {
                if (value === 'edit') {
                  setUpdatePayload({
                    ...u,
                    role_id: u?.roles?.[0]?.id,
                  });
                  setManageOpen(true);
                }
                if (value === 'delete') {
                  setDeleteDialogOpen(true);
                  setDeleteUserId(u.id);
                }
                if (value === 'link') {
                  if (u.invitation_link) {
                    copyContent(u.invitation_link);

                    makeToastify().show('Link copiado com sucesso!', {
                      type: 'success',
                    });
                  }
                }
              }}
            />
          ),
        }))}
      />

      <Box justifyContent="center">
        <Pagination
          activePage={page}
          className="pagination"
          itemClass="page-item"
          linkClass="page-link"
          activeClass="active-page"
          itemsCountPerPage={15}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </Box>

      <ManageDrawer
        key={updatePayload?.id}
        updatePayload={updatePayload}
        setUpdatePayload={setUpdatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          fetchUsers('', 1);
        }}
      />

      <Dialog
        loading={deleteLoading}
        open={deleteDialogOpen}
        onOpenChange={(st) => {
          setDeleteDialogOpen(st);

          if (!st) {
            setDeleteUserId('');
          }
        }}
        title={t('users.deleteUser')}
        trigger={<></>}
        onSubmit={() => {
          deleteUser();
        }}
        submitText={t('users.delete')}
        content={
          <Box gap={16}>
            <Typography variant="body" fontSize={14} color="gray">
              {t('users.deleteConfirmation')}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default UsersConfig;
