import React, { ReactElement, useContext, useState } from 'react';
import { Box, Button, Dialog, Input, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import DownloadIcon from './icons/downloadIcon.svg';
import { makeImportDealsUseCase } from 'main/factories/usecases/deal/import-deals-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import DealContext from '../contexts/DealContext';
import { useTranslation } from 'react-i18next';
interface ImportForm {
  file: File;
}

interface importDalsParams {
  callback: () => void;
}

export const ImportDeals = (params: importDalsParams): ReactElement => {
  const { t } = useTranslation();
  const { callback } = params;
  const { register, handleSubmit } = useForm<ImportForm>();
  const [loading, setLoading] = useState(false);

  const { importDialogOpen, setImportDialogOpen } = useContext(DealContext);

  async function handleImportSubmit(data: ImportForm): Promise<void> {
    try {
      setLoading(true);
      await makeImportDealsUseCase().execute({
        file: data.file[0],
      });
      callback();
      setTimeout(() => {
        pressEscKey();
      }, 500);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleDownloadFromUrl(): void {
    window.open(
      'https://minocrm.s3.sa-east-1.amazonaws.com/modelo-deals.xlsx',
      '_blank'
    );
  }

  return (
    <Dialog
      title={t('deal.import_deals')}
      onSubmit={handleSubmit(handleImportSubmit)}
      loading={loading}
      open={importDialogOpen}
      onOpenChange={() => setImportDialogOpen(false)}
      content={
        <Box flexDirection="column" gap={24}>
          <Box flexDirection="column" gap={16}>
            <Box flexDirection="column" gap={8}>
              <Box flexDirection="column" gap={16}>
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  {t('deal.import_deals_description')}
                </Typography>
                <Button
                  onClick={handleDownloadFromUrl}
                  size="medium"
                  text={t('deal.download_model')}
                  variant="ghost"
                  icon={DownloadIcon}
                />
              </Box>
            </Box>

            <form onSubmit={handleSubmit(handleImportSubmit)}>
              <Box gap={16} flexDirection="column">
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  {t('deal.import_deals_description_2')}
                </Typography>
                <Input
                  name="file"
                  type="file"
                  register={register}
                  validations={{
                    required: true,
                  }}
                />
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  {t('deal.import_deals_description_3')}
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};
