import React, { ReactElement, useState } from 'react';
import { Box, Button, Input, Table, Typography, Dialog } from 'mino-ui';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadFunnels } from 'domain/usecases/funnel/save-funnels-use-case';
import { useFunnels } from 'main/hooks/useFunnels';
import { makeSaveFunnelUseCase } from 'main/factories/usecases/funnel/save-funnels-factory';
import { makeDeleteFunnelUseCase } from 'main/factories/usecases/funnel/delete-funnel-factory';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
const FunnelsConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadFunnels>();
  const [deleteListing, setDeleteListing] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);

  const { funnels, fetchFunnels } = useFunnels();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RequestPayloadFunnels>();

  async function saveNewFunnel(data: RequestPayloadFunnels) {
    try {
      setCreateLoading(true);
      await makeSaveFunnelUseCase().execute(data, 'create');

      fetchFunnels();
      setValue('name', '');
      setValue('order', 0);
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }

  async function deleteFunnel(funnelId: string) {
    try {
      setDeleteListing(true);
      await makeDeleteFunnelUseCase(funnelId).execute();

      fetchFunnels();
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteListing(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form
          onSubmit={handleSubmit(saveNewFunnel)}
          style={{ display: 'flex', gap: 8 }}
        >
          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            validations={{
              required: true,
              maxLength: 18,
              minLength: 3,
            }}
            placeholder={t('funnels.name')}
            name="name"
          />

          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            placeholder={t('funnels.order')}
            validations={{
              required: true,
              max: 10,
            }}
            name="order"
            type="number"
          />
          <Button
            disabled={createLoading}
            icon={AddIcon}
            text={t('funnels.add')}
            type="submit"
          />
        </form>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: t('funnels.name'),
            width: 50,
          },
          {
            field: 'order',
            headerName: t('funnels.order'),
            hiddenOnMobile: true,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={funnels.map((f) => ({
          id: f.id,
          name: f.name,
          order: f.order.toString(),
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                onClick={() => {
                  setUpdatePayload(f);
                  setManageOpen(true);
                }}
                variant="ghost"
                text={t('funnels.edit')}
              />

              <Dialog
                modal
                title={t('funnels.attention')}
                trigger={
                  <Button
                    size="small"
                    variant="secondary"
                    text={t('funnels.delete')}
                  />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      {t('funnels.deleteConfirmation')}
                    </Typography>

                    <Box gap={8}>
                      <Button
                        disabled={deleteListing}
                        onClick={() => deleteFunnel(f.id)}
                        variant="primary"
                        text={
                          deleteListing
                            ? t('funnels.removing')
                            : t('funnels.confirm')
                        }
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text={t('funnels.cancel')}
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          fetchFunnels();
          setUpdatePayload(undefined);
        }}
      />
    </Box>
  );
};

export default FunnelsConfig;
