import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GlobalStyles from '../themes';
import { ThemeProvider } from 'styled-components';
import { light } from '../themes/light';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'main/routes';
import { SessionContext } from './contexts/SessionContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { ISession } from 'domain/entity/ISession';
import { makeGetSession } from 'main/factories/usecases/sessions/get-session-factory';
import { ensureAuthentication } from 'main/middlewares/EnsureAuthentication';
import runOneSignal from './onesignal';
import { GlobalLoading } from './loading';
import { LayoutTemplate } from './template';
import { QueryClient, QueryClientContext } from '@tanstack/react-query';

const Layout = (): JSX.Element => {
  const [session, setSession] = useState<ISession>({} as ISession);

  const isAuthenticated = ensureAuthentication();

  async function fetchSessionData() {
    try {
      const data = await makeGetSession().execute();

      setSession(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchSessionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session.id) {
      runOneSignal(session.id);
    }
  }, [session]);

  const queryClient = new QueryClient();

  return (
    <QueryClientContext.Provider value={queryClient}>
      <LanguageProvider>
        <SessionContext.Provider
          value={{
            session,
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={light}>
              <Router>
                {!isAuthenticated ? (
                  <Routes />
                ) : session.id ? (
                  <LayoutTemplate session={session} />
                ) : (
                  <GlobalLoading />
                )}
              </Router>
            </ThemeProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              limit={3}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <GlobalStyles />
          </DndProvider>
        </SessionContext.Provider>
      </LanguageProvider>
    </QueryClientContext.Provider>
  );
};

export default Layout;
