import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Avatar, Badge, Icon, PopOver, Typography } from 'mino-ui';
import { RenderIconColor, renderIcon } from './actions';
import * as S from './styles';
import * as T from './types';
import { formatBrazilianDate } from 'main/helpers/formatBrazilianDate';
import { ActivityFileList } from '../ActivityTabs/atoms/ActivityFileList';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { ActivityType } from 'domain/entity/IActivity';
import { useTranslation } from 'react-i18next';
import { translateText } from 'i18n/helpers/azure-translator';

const ActivityCard = (props: T.CardActivityProps): ReactElement => {
  const { content, type, date, time, userName, avatar, title, files } = props;
  const activityCardRef = useRef<HTMLDivElement>(null);
  const activityDescriptionRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  function isContentHTML(value: string) {
    const regex = /(<([^>]+)>)/gi;
    return regex.test(value);
  }

  const [translatedContent, setTranslatedContent] = useState(content);

  useEffect(() => {
    translateText(content?.toString() || '').then((res) =>
      setTranslatedContent(res)
    );
  }, [content]);

  return (
    <S.Container>
      <S.Header>
        <S.Title type={type}>
          <Icon iconName={renderIcon(type)} color={RenderIconColor(type)} />
          {t(`activities.${type}`, { defaultValue: title || type })}
        </S.Title>

        <S.UserAndDate>
          {files && files?.length > 0 && (
            <PopOver
              align="start"
              trigger={
                <div style={{ display: 'flex', gap: '4px' }}>
                  <Icon iconName="Attach20Regular" />
                  <Badge value={files?.length} />
                </div>
              }
              content={<ActivityFileList files={files} />}
            />
          )}
          <S.User>
            <Avatar
              fallback={userName}
              image={avatar}
              width={18}
              name={userName || 'N O'}
            />

            {userName ? (
              <Typography
                fontSize={12}
                weight="regular"
                variant="body"
                lineHeight={18}
              >
                {userName}
              </Typography>
            ) : null}
          </S.User>

          {date && (
            <S.TypographyWraper>
              <Icon iconName="CalendarLtr20Regular" color={IconColors.gray} />
              <Typography
                weight="regular"
                fontSize={12}
                variant="body"
                color="gray"
              >
                {formatBrazilianDate({ date: new Date(date) })}
              </Typography>
            </S.TypographyWraper>
          )}

          {time && (
            <S.TypographyWraper>
              <Icon iconName="Clock20Regular" color={IconColors.gray} />
              <Typography
                weight="regular"
                fontSize={12}
                variant="body"
                color="gray"
              >
                {formatBrazilianDate({ date: new Date(time), time: true })}
              </Typography>
            </S.TypographyWraper>
          )}
        </S.UserAndDate>
      </S.Header>
      <S.Content ref={activityCardRef}>
        {typeof content === 'string' ? (
          <>
            {isContentHTML(content) ? (
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                style={{
                  fontSize: '80%',
                  background: type === ActivityType.EMAIL ? '#f9f9f9' : 'none',
                  padding: 16,
                  borderRadius: 8,
                }}
              />
            ) : (
              <Typography
                ref={activityDescriptionRef}
                noWrap
                lineHeight={16.39}
                fontSize={12}
                weight="regular"
                color="gray"
                variant="body"
              >
                {translatedContent}
              </Typography>
            )}
          </>
        ) : (
          translatedContent
        )}
      </S.Content>
    </S.Container>
  );
};

export default ActivityCard;
