import i18next from 'i18next';

function getTranslatedLabel(label: string) {
  return i18next.t(`sideBar.${label}`);
}

export const bodyLinks = [
  {
    icon: 'Home20Regular',
    label: getTranslatedLabel('home'),
    navTo: '/',
    route: '/',
  },
  {
    icon: 'Briefcase20Regular',
    label: getTranslatedLabel('deals'),
    navTo: '/deals',
    route: '/deals',
  },
  {
    icon: 'BookContacts20Regular',
    label: getTranslatedLabel('contacts'),
    navTo: '/contacts',
    route: '/contacts',
  },
  {
    icon: 'BuildingMultiple20Regular',
    label: getTranslatedLabel('companies'),
    navTo: '/companies',
    route: '/companies',
  },
  {
    icon: 'TasksApp20Regular',
    label: getTranslatedLabel('activities'),
    navTo: '/activities',
    route: '/activities',
  },
  {
    icon: 'Chat20Regular',
    label: getTranslatedLabel('messages'),
    navTo: '/chat',
    route: '/chat',
  },
  {
    icon: 'ContentViewGallery20Regular',
    label: getTranslatedLabel('dashboard'),
    navTo: '/panel',
    route: '/panel',
  },
  {
    icon: 'ContentViewGallery20Regular',
    label: getTranslatedLabel('workflows'),
    navTo: '/workflows',
    route: '/workflows',
  },
];

export const footerLinks = [
  {
    icon: 'Pin20Regular',
    label: getTranslatedLabel('customerPortal'),
    navTo: 'https://billing.stripe.com/p/login/3csdSS4xVcudbteeUU',
    external: true,
  },
  {
    icon: 'Settings20Regular',
    label: getTranslatedLabel('settings'),
    navTo: '/config',
    route: '/config',
  },
  {
    icon: 'QuestionCircle20Regular',
    label: getTranslatedLabel('supportAndHelp'),
    navTo: 'https://minosuite.freshdesk.com/support/home',
    external: true,
  },
];
