import React, { ReactElement } from 'react';
import { Dropdown, Table } from 'mino-ui';
import { ICustomField } from 'domain/entity/ICustomField';
import { useTranslation } from 'react-i18next';
interface CustomFieldsTableProps {
  customFields: ICustomField[];
  setPayload: (payload: ICustomField) => void;
  setIdToDelete: (id: string) => void;
  setModalOpen: (isOpen: boolean) => void;
}

export const CustomFieldsDataTable = (
  props: CustomFieldsTableProps
): ReactElement => {
  const { customFields, setModalOpen, setPayload, setIdToDelete } = props;
  const { t } = useTranslation();

  function translateType(type: string) {
    switch (type) {
      case 'text':
        return t('customFields.text');
      case 'number':
        return t('customFields.number');
      case 'date':
        return t('customFields.date');
      case 'boolean':
        return t('customFields.boolean');
      case 'select':
        return t('customFields.select');
      default:
        return '';
    }
  }

  return (
    <Table
      hasOutline
      compact
      columns={[
        { field: 'name', headerName: t('customFields.name') },
        { field: 'type', headerName: t('customFields.type') },
        { field: 'action', headerName: '', align: 'right' },
      ]}
      rows={customFields.map((field) => ({
        id: `${field.id}`,
        name: field.name,
        type: translateType(field.type),
        action: (
          <Dropdown
            trigger="action-button"
            items={[
              {
                label: t('customFields.edit'),
                icon: 'Edit20Regular',
                value: 'edit',
              },
              {
                label: t('customFields.delete'),
                icon: 'Delete20Regular',
                iconColor: 'red' as any,
                value: 'delete',
              },
            ]}
            onClick={(value) => {
              if (value === 'edit') {
                setPayload(field);
                setModalOpen(true);
              }
              if (value === 'delete') {
                setIdToDelete(field.id);
              }
            }}
          />
        ),
      }))}
    />
  );
};
