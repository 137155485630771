import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { WebPubSubClient } from '@azure/web-pubsub-client';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Input,
  Spinner,
  Typography,
} from 'mino-ui';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import * as S from './styles';
import { format } from 'date-fns';
import { useUsers } from 'main/hooks/useUsers';
import { pressEscKey } from 'main/helpers';
import { useTranslation } from 'react-i18next';
interface ChatMessage {
  userId: string;
  userName: string;
  message: string;
  recipientId: string;
  createdAt: string;
}

interface ChatRecipient {
  recipientId: string;
  name: string;
  isGroup: boolean;
  users: string[];
}

const Chat: React.FC = () => {
  const { users } = useUsers();

  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);
  const userId = sessionContext?.session?.id || '';
  const userName = sessionContext?.session?.username || 'Anonymous';

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState<string>('');
  const [client, setClient] = useState<WebPubSubClient | null>(null);
  const [selectedRecipient, setSelectedRecipient] = useState<string | null>(
    null
  );
  const [recipients, setRecipients] = useState<ChatRecipient[]>([]);
  const [isLoadingRecipients, setIsLoadingRecipients] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  const loadMessageHistory = async (recipientId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CHAT_API_URL}/messages/${recipientId}`
      );
      if (!response.ok) {
        throw new Error(`Failed to load messages: ${response.statusText}`);
      }

      const historicalMessages = await response.json();
      setMessages(historicalMessages);
    } catch (error) {
      console.error('Failed to load message history:', error);
      setError('Failed to load message history. Please try again.');
    }
  };

  useEffect(() => {
    let isMounted = true;
    let newClient: WebPubSubClient | null = null;

    const negotiateConnection = async () => {
      if (!userId || !selectedRecipient) {
        console.error('User ID or Recipient ID is not available');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_CHAT_API_URL}/negotiate?userId=${userId}&recipientId=${selectedRecipient}`
        );
        const { url } = await response.json();

        newClient = new WebPubSubClient(url);

        newClient.on('connected', async () => {
          console.log('Connected to Web PubSub');

          try {
            await newClient?.joinGroup(selectedRecipient);
            console.log('Joined group', selectedRecipient);
          } catch (err) {
            console.error('Failed to join group:', err);
          }
        });

        newClient.on('disconnected', () => {
          console.log('Disconnected from Web PubSub');
        });

        newClient.on('group-message', (payload: any) => {
          const { message } = payload;
          const { data } = message;

          const parsedData = JSON.parse(data);

          if (parsedData.type === 'message' && parsedData.data) {
            const newMessage = parsedData.data;
            if (
              newMessage.recipientId === selectedRecipient ||
              (newMessage.recipientId === userId &&
                newMessage.userId === selectedRecipient)
            ) {
              setMessages((prev) => [...prev, newMessage]);
            }
          }
        });

        await newClient.start();

        if (isMounted) {
          setClient(newClient);
        }
      } catch (error) {
        console.error('Failed to negotiate connection:', error);
      }
    };

    negotiateConnection();

    return () => {
      isMounted = false;
      if (newClient) {
        newClient.stop();
        console.log('Web PubSub client stopped');
      }
    };
  }, [userId, selectedRecipient]);

  useEffect(() => {
    const loadRecipients = async () => {
      setIsLoadingRecipients(true);
      setError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CHAT_API_URL}/recipients`
        );
        if (!response.ok) throw new Error('Failed to load recipients');

        const recipientsList = await response.json();
        const userRecipients = recipientsList.filter(
          (recipient: ChatRecipient) => recipient.users.includes(userId)
        );

        setRecipients(userRecipients);
      } catch (err) {
        console.error('Failed to load recipients:', err);
        setError('Failed to load recipients. Please try again later.');
      } finally {
        setIsLoadingRecipients(false);
      }
    };

    loadRecipients();
  }, [userId]);

  const createNewRecipient = async (
    name: string,
    selectedUserIds: string[]
  ) => {
    try {
      const recipientId = crypto.randomUUID();

      const recipientData: ChatRecipient = {
        recipientId: recipientId,
        name: name,
        isGroup: true,
        users: [userId, ...selectedUserIds],
      };

      const response = await fetch(
        `${process.env.REACT_APP_CHAT_API_URL}/recipients`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(recipientData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.title || 'Failed to create recipient');
      }

      const newRecipient = await response.json();
      setRecipients((prev) => [...prev, newRecipient]);
      setSelectedRecipient(newRecipient.recipientId);
      setMessages([]);
      await loadMessageHistory(newRecipient.recipientId);
      return newRecipient;
    } catch (err) {
      console.error('Failed to create recipient:', err);
      throw err;
    }
  };

  const sendMessage = async () => {
    if (!client || !input.trim() || !selectedRecipient) {
      return;
    }

    const messageToSend = {
      userId,
      userName,
      message: input.trim(),
      recipientId: selectedRecipient,
      createdAt: new Date().toISOString(),
    };

    try {
      setInput('');

      const response = await fetch(
        `${process.env.REACT_APP_CHAT_API_URL}/send`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(messageToSend),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to send message: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      setError('Failed to send message. Please try again.');

      // Remove the optimistically added message if the send fails
      setMessages((prev) =>
        prev.filter((msg) => msg.createdAt !== messageToSend.createdAt)
      );
    }
  };

  const deleteRecipient = async (recipientId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CHAT_API_URL}/recipients/${recipientId}`,
        {
          method: 'DELETE',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete chat');
      }

      // Remove from local state
      setRecipients((prev) =>
        prev.filter((r) => r.recipientId !== recipientId)
      );

      // If the deleted chat was selected, clear the selection
      if (selectedRecipient === recipientId) {
        setSelectedRecipient(null);
        setMessages([]);
      }
    } catch (err) {
      console.error('Failed to delete chat:', err);
      setError('Failed to delete chat. Please try again.');
    }
  };

  return (
    <S.Container>
      <S.LeftContainer>
        <Box flexDirection="column" gap={8} padding={16}>
          <CreateRecipientDialog
            onCreateChat={createNewRecipient}
            users={users}
            currentUserId={userId}
          />
          {error && (
            <Typography variant="body" style={{ color: 'red' }}>
              {error}
            </Typography>
          )}

          <Box
            flexDirection="column"
            gap={8}
            style={{
              marginTop: '16px',
              maxHeight: 'calc(100vh - 250px)',
              overflowY: 'auto',
            }}
          >
            <div
              style={{ height: '1px', width: '100%', backgroundColor: '#DDD' }}
            />
            {isLoadingRecipients ? (
              <Box alignItems="center" justifyContent="center">
                <Spinner size="small" />
              </Box>
            ) : recipients.length === 0 ? (
              <Typography variant="body">{t('chat.noChats')}</Typography>
            ) : (
              recipients.map((recipient) => (
                <Box
                  alignItems="center"
                  gap={8}
                  key={recipient.recipientId}
                  style={{
                    padding: '12px',
                    border: '1px solid #DDD',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    transition: 'background-color 0.2s',
                    ':hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                  onClick={async () => {
                    setSelectedRecipient(recipient.recipientId);
                    setMessages([]);
                    await loadMessageHistory(recipient.recipientId);
                  }}
                >
                  <Avatar name={recipient.name} />
                  <Typography variant="body">
                    {recipient.name}
                    {recipient.isGroup && (
                      <span style={{ color: '#666' }}> (Group)</span>
                    )}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </S.LeftContainer>

      <S.RightContainer>
        {selectedRecipient && (
          <>
            <Box width="100%" gap={8} flexDirection="column">
              <Box flexDirection="column" gap={8}>
                <Box alignItems="center" justifyContent="space-between" gap={8}>
                  <Box alignItems="center" gap={8}>
                    <Avatar
                      name={
                        recipients?.find(
                          (r) => r.recipientId === selectedRecipient
                        )?.name || 'Chat'
                      }
                    />
                    <Typography variant="h4">
                      {recipients.find(
                        (r) => r.recipientId === selectedRecipient
                      )?.name || 'Chat'}
                      {recipients.find(
                        (r) => r.recipientId === selectedRecipient
                      )?.isGroup && (
                        <span style={{ color: '#666' }}> (Group)</span>
                      )}
                    </Typography>
                  </Box>
                  <Button
                    variant="secondary"
                    text={t('chat.deleteChat')}
                    onClick={() => {
                      if (window.confirm(t('chat.deleteChatConfirmation'))) {
                        deleteRecipient(selectedRecipient);
                      }
                    }}
                  />
                </Box>

                <Box flexDirection="row" gap={8} style={{ flexWrap: 'wrap' }}>
                  <Typography variant="body" fontSize={12} color="gray">
                    {recipients
                      .find((r) => r.recipientId === selectedRecipient)
                      ?.users.map(
                        (participantId) =>
                          users.find((u) => u.id === participantId)?.name ||
                          t('chat.unknownUser')
                      )
                      .join(', ')}
                  </Typography>
                </Box>

                <div
                  style={{
                    height: '1px',
                    width: '100%',
                    backgroundColor: '#DDD',
                  }}
                />
              </Box>

              <Box
                flexDirection="column"
                gap={8}
                style={{
                  border: '1px solid #DDD',
                  height: 'calc(100vh - 250px)',
                  borderRadius: '8px',
                  overflowY: 'scroll',
                  padding: '10px',
                  width: '100%',
                }}
              >
                {messages.map((msg, idx) => (
                  <Box
                    key={idx}
                    flexDirection="column"
                    style={{
                      alignSelf:
                        msg.userId === userId ? 'flex-end' : 'flex-start',
                      maxWidth: '70%',
                      backgroundColor:
                        msg.userId === userId ? '#e3f2fd' : '#f5f5f5',
                      padding: '8px 12px',
                      borderRadius: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <Typography variant="body" style={{ color: '#666' }}>
                      {msg.userName} •{' '}
                      {format(new Date(msg.createdAt), 'HH:mm')}
                    </Typography>
                    <Typography variant="body">{msg.message}</Typography>
                  </Box>
                ))}
                <div ref={messagesEndRef} />
              </Box>
            </Box>

            <Box width="100%" alignItems="center" gap={8}>
              <Input
                full
                name="chat-input"
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={t('chat.newMessageText')}
                onEnter={sendMessage}
              />
              <Button onClick={sendMessage} text={t('chat.send')} />
            </Box>
          </>
        )}
      </S.RightContainer>
    </S.Container>
  );
};

export default Chat;

interface CreateRecipientDialogProps {
  onCreateChat: (
    name: string,
    selectedUsers: string[]
  ) => Promise<ChatRecipient>;
  users: Array<{ id: string; name: string }>;
  currentUserId: string;
}

function CreateRecipientDialog({
  onCreateChat,
  users,
  currentUserId,
}: CreateRecipientDialogProps): ReactElement {
  const [chatName, setChatName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleCreateChat = async () => {
    if (!chatName.trim() || selectedUsers.length === 0) return;

    setIsLoading(true);
    setError(null);

    try {
      await onCreateChat(chatName.trim(), selectedUsers);
      setChatName('');
      setSelectedUsers([]);
      pressEscKey();
    } catch (err) {
      setError('Failed to create chat room');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      title={t('chat.newChat')}
      trigger={<Button text={t('chat.newChat')} />}
      content={
        <Box flexDirection="column" gap={16} padding={16}>
          {error && (
            <Typography variant="body" style={{ color: 'red' }}>
              {error}
            </Typography>
          )}

          <Box flexDirection="column" gap={8}>
            <Typography variant="body">{t('chat.chatName')}</Typography>
            <Input
              name="chat-name"
              type="text"
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
              placeholder={t('chat.chatNamePlaceholder')}
            />
          </Box>

          <Box flexDirection="column" gap={8}>
            <Typography variant="body">
              {t('chat.selectParticipants')}
            </Typography>
            {users
              .filter((user) => user.id !== currentUserId)
              .map((user) => (
                <Box
                  key={user.id}
                  alignItems="center"
                  gap={8}
                  style={{
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor: selectedUsers.includes(user.id)
                      ? '#e3f2fd'
                      : 'transparent',
                    borderRadius: '4px',
                  }}
                  onClick={() => {
                    setSelectedUsers((prev) =>
                      prev.includes(user.id)
                        ? prev.filter((id) => id !== user.id)
                        : [...prev, user.id]
                    );
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    readOnly
                  />
                  <Typography variant="body">{user.name}</Typography>
                </Box>
              ))}
          </Box>

          <Box justifyContent="flex-end" gap={8}>
            <Button
              onClick={() => pressEscKey()}
              text={t('chat.cancel')}
              variant="secondary"
            />
            <Button
              onClick={handleCreateChat}
              text={isLoading ? <Spinner size="small" /> : t('chat.createChat')}
              disabled={
                !chatName.trim() || selectedUsers.length === 0 || isLoading
              }
            />
          </Box>
        </Box>
      }
    />
  );
}
