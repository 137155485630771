import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
export const translateText = async (text: string) => {
  const endpoint = process.env.REACT_APP_AZURE_TRANSLATOR_ENDPOINT;
  const key = process.env.REACT_APP_AZURE_TRANSLATOR_KEY;

  const language = localStorage.getItem('i18nextLng');
  try {
    const response = await axios.post(`${endpoint}/translate`, {
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': key,
        'X-ClientTraceId': uuidv4().toString(),
        'Ocp-Apim-Subscription-Region': 'Global',
      },
      params: {
        'api-version': '3.0',
        to: language,
      },
      data: [{ text }],
    });

    const data = response.data;
    return data[0].translations[0].text;
  } catch (error) {
    console.error(error);
    return text;
  }
};
