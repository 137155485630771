import React, { ReactElement, useState } from 'react';
import { Box, Button, Dialog, Table, Typography } from 'mino-ui';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { useGroups } from 'main/hooks/useGroups';
import { makeDeleteGroupUseCase } from 'main/factories/usecases/group/delete-group-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { SaveRoleRequest } from 'domain/usecases/role/save-role-use-case';
import { IRole } from 'domain/entity/IRole';
import { useTranslation } from 'react-i18next';

const GroupsConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<SaveRoleRequest>();

  const { groups, fetchGroups } = useGroups();

  const { t } = useTranslation();

  async function deleteGroup(userId: string) {
    try {
      setDeleteLoading(true);
      await makeDeleteGroupUseCase(userId).execute();
      fetchGroups();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <Button
          icon={AddIcon}
          onClick={() => {
            setManageOpen(true);
            setUpdatePayload(undefined);
          }}
          text={t('group.add')}
        />
      </Box>
      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: t('group.name'),
            width: 100,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={groups.map((g: IRole) => ({
          id: g.id as string,
          name: g.name,
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                variant="ghost"
                text={t('group.edit')}
                onClick={() => {
                  setUpdatePayload({
                    id: g.id,
                    name: g.name,
                    permissions: g.permissions,
                  });
                  setManageOpen(true);
                }}
              />
              <Dialog
                modal
                title={t('group.attention')}
                trigger={
                  <Button
                    size="small"
                    variant="secondary"
                    text={t('group.delete')}
                  />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      {t('group.deleteConfirmation')}
                    </Typography>

                    <Box gap={8}>
                      <Button
                        disabled={deleteLoading}
                        onClick={() => deleteGroup(g.id || '')}
                        variant="primary"
                        text={
                          deleteLoading
                            ? t('group.removing')
                            : t('group.confirm')
                        }
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text={t('group.cancel')}
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />
      <ManageDrawer
        updatePayload={updatePayload}
        callback={() => {
          fetchGroups();
          setUpdatePayload(undefined);
          setManageOpen(false);
        }}
        setIsOpen={setManageOpen}
        isOpen={manageOpen}
      />
    </Box>
  );
};

export default GroupsConfig;
