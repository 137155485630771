export const en = {
  // Input validation messages
  required: 'Required field',
  minLength: 'Must contain at least {0} characters',
  maxLength: 'Cannot contain more than {0} characters',
  min: 'Cannot be less than {0}',
  max: 'Cannot be greater than {0}',
  pattern: 'This field is invalid',

  // Dialog component
  save: 'Save',
  cancel: 'Cancel',
};
