import React, { ReactElement, useState } from 'react';
import { Box, Button, Input, Table, Typography, Dialog } from 'mino-ui';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadChannels } from 'domain/usecases/channel/create-channel-use-case';
import { useChannels } from 'main/hooks/useChannel';
import { makeSaveChannelUseCase } from 'main/factories/usecases/channel/save-channel-factory';
import { makeDeleteChannelUseCase } from 'main/factories/usecases/channel/delete-channel-factory';
import { useForm } from 'react-hook-form';
import { formatBrazilianDate } from 'main/helpers';
import { useTranslation } from 'react-i18next';

const ChannelsConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadChannels>();
  const [deleteListing, setDeleteListing] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);

  const { t } = useTranslation();

  const { channels, fetchChannels } = useChannels();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RequestPayloadChannels>();

  async function saveNewChannel(data: RequestPayloadChannels) {
    try {
      setCreateLoading(true);
      await makeSaveChannelUseCase().execute(data, 'create');

      fetchChannels();
      setValue('name', '');
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }

  async function deleteChannel(channelId: string) {
    try {
      setDeleteListing(true);
      await makeDeleteChannelUseCase(channelId).execute();
      fetchChannels();
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteListing(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form
          onSubmit={handleSubmit(saveNewChannel)}
          style={{ display: 'flex', gap: 8 }}
        >
          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
            placeholder={t('channels.name')}
            name="name"
          />

          <Button
            disabled={createLoading}
            icon={AddIcon}
            text={t('channels.add')}
            type="submit"
          />
        </form>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: t('channels.name'),
            width: 50,
          },
          {
            field: 'created',
            headerName: t('channels.created'),
            hiddenOnMobile: true,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={channels.map((c) => ({
          id: c.id,
          name: c.name,
          created: formatBrazilianDate({
            date: new Date(c.created_at),
          }),
          actions: (
            <Box gap={8}>
              <Button
                size="small"
                onClick={() => {
                  setUpdatePayload(c);
                  setManageOpen(true);
                }}
                variant="ghost"
                text={t('channels.edit')}
              />

              <Dialog
                modal
                title={t('channels.attention')}
                trigger={
                  <Button
                    text={t('channels.delete')}
                    size="small"
                    variant="secondary"
                  />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      {t('channels.deleteConfirmation')}
                    </Typography>

                    <Box gap={8}>
                      <Button
                        variant="primary"
                        disabled={deleteListing}
                        text={
                          deleteListing
                            ? t('channels.removing')
                            : t('channels.delete')
                        }
                        onClick={() => deleteChannel(c.id)}
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text={t('channels.cancel')}
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          fetchChannels();
          setUpdatePayload(undefined);
        }}
      />
    </Box>
  );
};

export default ChannelsConfig;
