import React, { ReactElement, useContext } from 'react';
import { Button, Card, Icon, Typography } from 'mino-ui';
import * as S from './styles';
import * as T from './types';
import { IconColors } from '../..';
import ActivityListContext from '../../context/ActivityListContext';
import { renderIcon } from '../ListActivityCard/actions';
import { firstLetterUpper } from 'main/helpers';
import SpiralCalendar from './icons/Spiral-calendar.svg';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';

const TaskCard = (props: T.TaskCardProps): ReactElement => {
  const { activities } = props;
  const { t } = useTranslation();
  const { setIsDrawerOpen } = useContext(ActivityListContext);

  const dailyActivity = activities?.filter(
    (a) =>
      new Date(`${a?.start}`)?.toJSON()?.slice(0, 10) ==
      new Date()?.toJSON()?.slice(0, 10)
  );

  return (
    <S.Container>
      <Card direction="column">
        <S.Header>
          <Typography
            variant="body"
            color="dark"
            fontSize={14}
            weight="bold"
            lineHeight={14}
          >
            {t('activities.todaysTasks')}
          </Typography>
        </S.Header>
        <S.Body>
          <S.ListWrap>
            {dailyActivity && dailyActivity.length > 0 ? (
              dailyActivity.map((a, index) => (
                <S.Item key={index} type={a.type}>
                  <Icon iconName={renderIcon(a.type)} />
                  <Typography
                    noWrap
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    lineHeight={16}
                  >
                    {firstLetterUpper(a.title)}
                  </Typography>
                  <div style={{ width: 'fit-content' }}>
                    <Typography
                      variant="body"
                      color="gray"
                      fontSize={12}
                      weight="bold"
                      lineHeight={16}
                    >
                      {new Date(`${a.start}`).toLocaleTimeString().slice(0, -3)}
                    </Typography>
                  </div>
                </S.Item>
              ))
            ) : (
              <S.EmpityState>
                <ReactSVG src={SpiralCalendar} />
                <Typography
                  variant="body"
                  fontSize={14}
                  lineHeight={19}
                  weight="regular"
                  color="gray"
                >
                  {t('activities.noTasks')}
                </Typography>
                <Button
                  text={t('activities.create')}
                  variant="ghost"
                  icon="AddCircle20Regular"
                  iconColor={IconColors.blue}
                  onClick={() => setIsDrawerOpen(true)}
                />
              </S.EmpityState>
            )}
          </S.ListWrap>
        </S.Body>
      </Card>
    </S.Container>
  );
};
export default TaskCard;
