import React, { ReactElement, useContext, useState } from 'react';
import { IContact } from 'domain/entity/IContact';
import { firstLetterUpper } from 'main/helpers/firstLetterUpper';
import { Avatar, Box, Table } from 'mino-ui';
import { Link } from 'react-router-dom';
import ContactListContext from './context/ContactListContext';
import BulkOwnerContacts from './components/bulkOwner/component';
import BulkTagsContacts from './components/bulkTags/component';
import { useTranslation } from 'react-i18next';
interface TableProps {
  contacts: IContact[];
  loading: boolean;
}

export enum IconColors {
  dark = 'dark',
  white = 'white',
}

export const ContactListTable = (props: TableProps): ReactElement => {
  const { contacts, loading } = props;
  const { t } = useTranslation();

  const {
    activePage,
    totalItemsCount,
    handlePageChange,
    setBulkContacts,
    setBulkContactsTags,
    filter,
    setFilter,
  } = useContext(ContactListContext);

  const [ids, setIds] = useState<string[]>([]);

  return (
    <>
      <Table
        pageRangeDisplayed={5}
        itemsCountPerPage={15}
        hasBulkActions
        bulkActions={[
          {
            label: t('contact.changeResponsible'),
            action: (ids) => {
              setBulkContacts(true);
              setIds(ids);
            },
            buttonVariant: 'ghost',
            iconName: 'PersonSync20Regular',
            iconColor: IconColors.dark,
          },
          {
            label: t('contact.changeTags'),
            action: (ids) => {
              setBulkContactsTags(true);
              setIds(ids);
            },
            buttonVariant: 'ghost',
            iconName: 'Tag',
            iconColor: IconColors.dark,
          },
        ]}
        activePage={activePage}
        onChangePage={handlePageChange}
        totalItemsCount={totalItemsCount}
        loading={loading}
        onSort={(sort) => {
          setFilter({
            ...filter,
            sort_column: sort.field,
            sort_direction: sort.order,
          });
        }}
        columns={[
          {
            field: 'name',
            headerName: t('contact.name'),
            sortable: true,
            template: (contact) => (
              <Box alignItems="center">
                <Avatar name={contact.name} />
                <Link to={`/contacts/${contact.id}`} style={{ marginLeft: 8 }}>
                  {firstLetterUpper(contact.name)}
                </Link>
              </Box>
            ),
          },
          {
            field: 'email',
            headerName: t('contact.email'),
          },
          {
            field: 'phone',
            headerName: t('contact.phone'),
            hiddenOnMobile: true,
          },
          {
            field: 'company',
            headerName: t('contact.company'),
            hiddenOnMobile: true,
          },
        ]}
        rows={contacts?.map((contact) => ({
          id: contact.id.toString(),
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          company: contact.company ? contact.company.name : null,
        }))}
      />

      <BulkOwnerContacts
        callback={() => {
          handlePageChange(1);
        }}
        ids={ids}
      />
      <BulkTagsContacts ids={ids} />
    </>
  );
};
