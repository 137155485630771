import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  Datepicker,
  Drawer,
  Input,
  Select,
  Spacer,
  ToggleGroup,
} from 'mino-ui';
import DealContext from '../contexts/DealContext';
import { DrawerForm, FilterBadge, TriggerText, WrapTrigger } from './styles';
import { DealRequestPayload } from 'domain/usecases/deal/list-deals-use-case';
import { useForm } from 'react-hook-form';
import { useLoss } from 'main/hooks/useLoss';
import FilterIcon from './icons/filter.svg';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useTeams } from 'main/hooks/useTeams';
import { useChannels } from 'main/hooks/useChannel';
import { useServices } from 'main/hooks/useServices';
import { useContacts } from 'main/hooks/useContacts';
import { useCompanies } from 'main/hooks/useCompanies';
import { useTags } from 'main/hooks/useTags';
import { useCustomFields } from 'main/hooks/useCustomFields';
import { DealResult } from 'domain/entity/IDeal';
import { useTranslation } from 'react-i18next';

const DrawerFilterDeal = (): ReactElement => {
  const { t } = useTranslation();
  const { isFilterOpen, setIsFilterOpen, setFilter, filter, initialFilter } =
    useContext(DealContext);

  const { loss } = useLoss();

  const { users, fetchUsers } = useUsers();
  const { teams } = useTeams();
  const { channels } = useChannels();
  const { services, fetchServices } = useServices();
  const { contacts, fetchContacts } = useContacts();
  const { companies, fetchCompanies } = useCompanies();
  const { tags } = useTags();

  const [searchContact, setSearchContact] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [, setSearchTag] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchUser, setSearchUser] = useState('');

  const [stateFilter, setStateFilter] = useState(filter);

  function handleFilter(data: DealRequestPayload): void {
    setIsFilterOpen(false);
    setFilter({
      ...stateFilter,
      ...data,
    });
  }

  function resetFilter() {
    reset();
    setFilter(initialFilter);
  }

  const { register, handleSubmit, reset, watch, control, setValue } =
    useForm<DealRequestPayload>();

  useEffect(() => {
    setValue('result', filter.result);
    setValue('context', filter.context);
    setValue('loss_id', filter.loss_id);
    setValue('funnel_id', filter.funnel_id);
    setValue('users_ids', filter.users_ids);
    setValue('teams', filter.teams);
    setValue('date_from', filter.date_from);
    setValue('date_to', filter.date_to);
    setValue('date_closing_from', filter.date_closing_from);
    setValue('date_closing_to', filter.date_closing_to);
    setValue('date_loss_from', filter.date_loss_from);
    setValue('date_loss_to', filter.date_loss_to);
    setValue('channel_id', filter.channel_id);
    setValue('products', filter.products);
    setValue('contacts', filter.contacts);
    setValue('companies', filter.companies);
    setValue('tags', filter.tags);

    setStateFilter(filter);

    custom_fields.forEach((custom_field) => {
      setValue(`cf_${custom_field.id}`, filter[`cf_${custom_field.id}`]);
    });
  }, [filter]);

  useDebouncedEffect(
    () => {
      fetchUsers(searchUser);
    },
    [searchUser],
    300
  );

  useDebouncedEffect(
    () => {
      fetchServices(searchService);
    },
    [searchService],
    300
  );

  useDebouncedEffect(
    () => {
      fetchContacts(searchContact);
    },
    [searchContact],
    300
  );

  useDebouncedEffect(
    () => {
      fetchCompanies(searchCompany);
    },
    [searchCompany],
    300
  );

  const watchType = watch('context');
  const watchUsers = watch('users_ids');
  const watchResult = watch('result');

  const { custom_fields } = useCustomFields('deal');

  return (
    <Drawer
      width={300}
      isOpen={isFilterOpen}
      onClose={() => setIsFilterOpen(false)}
      title={t('deal.filter')}
      icon={FilterIcon}
      submitText={t('deal.filter')}
      onSubmit={handleSubmit(handleFilter)}
      secondarySubmitText={t('deal.clear')}
      secondarySubmitAction={() => {
        resetFilter();
        setIsFilterOpen(false);
      }}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <DrawerForm>
          <Collapse
            defaultOpen={true}
            hasArrow
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.name')}</TriggerText>
                {stateFilter.search && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <Input
                register={register}
                label={t('deal.name')}
                autoFocus
                name="search"
                placeholder={t('deal.placeholder')}
              />
            }
          />

          <Collapse
            hasArrow
            defaultOpen
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.responsible')}</TriggerText>
                {stateFilter.type !== initialFilter.type ? (
                  <FilterBadge />
                ) : null}
              </WrapTrigger>
            }
            content={
              <Box flexDirection="column" gap={8} alignItems="center">
                <ToggleGroup
                  size="small"
                  type="single"
                  onValueChange={(value) =>
                    setValue('context', value as DealRequestPayload['context'])
                  }
                  defaultValue={watchType}
                  items={[
                    {
                      value: 'self',
                      label: t('deal.my'),
                      icon: '',
                    },
                    {
                      value: 'all',
                      label: t('deal.all'),
                      icon: '',
                    },
                    {
                      value: 'user',
                      label: t('deal.user'),
                      icon: '',
                    },
                    {
                      value: 'team',
                      label: t('deal.team'),
                      icon: '',
                    },
                  ]}
                />

                {watchType === 'user' ? (
                  <Select
                    label={t('deal.users')}
                    fullWidth
                    control={control}
                    name="users_ids"
                    multi
                    defaultValue={watchUsers}
                    onInputChange={(value) => setSearchUser(value)}
                    options={users.map((u) => ({
                      value: u.id,
                      label: u.name,
                    }))}
                  />
                ) : null}

                {watchType === 'team' ? (
                  <Select
                    label={t('deal.teams')}
                    fullWidth
                    control={control}
                    name="teams"
                    multi
                    defaultValue={watch('teams')}
                    options={teams.map((t) => ({
                      value: t.id,
                      label: t.name,
                    }))}
                  />
                ) : null}
              </Box>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watchResult !== initialFilter.result}
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.result')}</TriggerText>
                {stateFilter.result !== DealResult.Open && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <Box flexDirection="column" gap={8}>
                <Select
                  fullWidth
                  control={control}
                  name="result"
                  options={[
                    {
                      value: '0',
                      label: t('deal.open'),
                    },
                    {
                      value: '1',
                      label: t('deal.won'),
                    },
                    {
                      value: '2',
                      label: t('deal.lost'),
                    },
                    {
                      value: '',
                      label: t('deal.all'),
                    },
                  ]}
                />

                {watchResult == 0 ? (
                  <Select
                    label={t('deal.loss_reason')}
                    fullWidth
                    control={control}
                    name="loss_id"
                    defaultValue={watch('loss_id')}
                    options={loss.map((l) => ({
                      label: l.name,
                      value: l.id,
                    }))}
                  />
                ) : null}
              </Box>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={
              (stateFilter.date_from || stateFilter.date_to) !==
              (initialFilter.date_from || initialFilter.date_to)
            }
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.creation_date')}</TriggerText>
                {(stateFilter.date_from || stateFilter.date_to) && (
                  <FilterBadge />
                )}
              </WrapTrigger>
            }
            content={
              <>
                <Datepicker
                  control={control}
                  label={t('deal.from')}
                  isClearable
                  name="date_from"
                  placeholder={t('deal.placeholder')}
                />
                <Spacer height={8} />
                <Datepicker
                  control={control}
                  isClearable
                  label={t('deal.to')}
                  name="date_to"
                  placeholder={t('deal.placeholder')}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={
              (stateFilter.date_loss_from || stateFilter.date_loss_to) !==
              (initialFilter.date_loss_from || initialFilter.date_loss_to)
            }
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.loss_date')}</TriggerText>
                {(stateFilter.date_loss_from || stateFilter.date_loss_to) && (
                  <FilterBadge />
                )}
              </WrapTrigger>
            }
            content={
              <>
                <Datepicker
                  control={control}
                  label={t('deal.from')}
                  isClearable
                  name="date_loss_from"
                  placeholder={t('deal.placeholder')}
                />
                <Spacer height={8} />
                <Datepicker
                  control={control}
                  isClearable
                  label={t('deal.to')}
                  name="date_loss_to"
                  placeholder={t('deal.placeholder')}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watch('products') !== initialFilter.products}
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.products')}</TriggerText>
                {stateFilter.products && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label={t('deal.products')}
                  fullWidth
                  control={control}
                  multi
                  name="products"
                  defaultValue={watch('products')}
                  onInputChange={(value) => setSearchService(value)}
                  options={services.map((s) => ({
                    label: s.name,
                    value: s.id,
                  }))}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watch('contacts') !== initialFilter.contacts}
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.contacts')}</TriggerText>
                {stateFilter.contacts && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label={t('deal.contacts')}
                  fullWidth
                  control={control}
                  multi
                  name="contacts"
                  defaultValue={watch('contacts')}
                  onInputChange={(value) => setSearchContact(value)}
                  options={contacts.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watch('companies') !== initialFilter.companies}
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.companies')}</TriggerText>
                {stateFilter.companies && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label={t('deal.companies')}
                  fullWidth
                  control={control}
                  multi
                  name="companies"
                  defaultValue={watch('companies')}
                  onInputChange={(value) => setSearchCompany(value)}
                  options={companies.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </>
            }
          />
          <Collapse
            hasArrow
            defaultOpen={watch('tags') !== initialFilter.tags}
            trigger={
              <WrapTrigger>
                <TriggerText>{t('deal.tags')}</TriggerText>
                {stateFilter.tags && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label={t('deal.tags')}
                  fullWidth
                  control={control}
                  multi
                  name="tags"
                  defaultValue={watch('tags')}
                  onInputChange={(value) => setSearchTag(value)}
                  options={tags.map((t) => ({
                    label: t.name,
                    value: t.id,
                  }))}
                />
              </>
            }
          />

          {custom_fields?.map((field) => (
            <Collapse
              key={field.id}
              hasArrow
              defaultOpen={stateFilter[`cf_${field.id}`]}
              trigger={
                <WrapTrigger>
                  <TriggerText>{field.name}</TriggerText>
                  {stateFilter[`cf_${field.id}`] && <FilterBadge />}
                </WrapTrigger>
              }
              content={
                <>
                  <Input
                    label={field.name}
                    full
                    register={register}
                    name={`cf_${field.id}`}
                    placeholder={`${field.name}`}
                  />
                </>
              }
            />
          ))}

          <Spacer height={16} />
          <input type="submit" hidden />
        </DrawerForm>
      </form>
    </Drawer>
  );
};

export default DrawerFilterDeal;
