import React, { ReactElement, useContext } from 'react';
import { Card, Typography, Spacer, Button, Icon } from 'mino-ui';
import * as S from '../ContactCard/styles';
import { IContact } from 'domain/entity/IContact';
import { SingleContactContext } from 'presentation/pages/Contact/contexts/SingleContactContext';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { copyContent, formatBrazilianDate } from 'main/helpers';
import { useTranslation } from 'react-i18next';
interface CardProps {
  contact?: IContact;
}
const ContactCard = (props: CardProps): ReactElement => {
  const { setEditContact, all_custom_fields } =
    useContext(SingleContactContext);
  const { contact } = props;
  const { t } = useTranslation();

  return (
    <S.Container>
      <Card direction="column">
        <S.Header>
          <S.TitleWrap>
            <S.IconTittle>
              <Icon iconName="BookContacts24Regular" color={IconColors.blue} />
            </S.IconTittle>
            <Typography
              variant="body"
              color="dark"
              fontSize={14}
              lineHeight={14}
              weight="bold"
              noWrap
            >
              {t('contact.details')}
            </Typography>
          </S.TitleWrap>

          <Button
            type="button"
            variant="ghost"
            text=""
            size="small"
            icon="Edit20Regular"
            onClick={() => setEditContact(true)}
          />
        </S.Header>
        <S.Body>
          <Spacer height={16} />
          <S.ListWrap>
            {contact?.name ? (
              <S.Item>
                <Icon
                  iconName="BookContacts20Regular"
                  color={IconColors.dark}
                />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.name')}: </span>
                    {contact?.name}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.email ? (
              <S.Item>
                <Icon iconName="Mail20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.email')}: </span>
                    {contact.email}
                  </Typography>
                  <button onClick={() => copyContent(`${contact.email}`)}>
                    <Icon iconName="Copy16Regular" color={IconColors.dark} />
                  </button>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.phone ? (
              <S.Item>
                <Icon iconName="Call20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.phone')}: </span>
                    {contact.phone}
                  </Typography>
                  <button onClick={() => copyContent(`${contact.phone}`)}>
                    <Icon iconName="Copy16Regular" color={IconColors.dark} />
                  </button>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.address && contact.city && contact.state ? (
              <S.Item>
                <Icon iconName="Location20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.address')}: </span>
                    {`${contact.address}, ${contact.city}, ${contact.state}`}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.cpf ? (
              <S.Item>
                <Icon iconName="Info20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography variant="body" color="gray" fontSize={12} noWrap>
                    <span>{t('contact.cpf')}: </span>
                    {contact.cpf}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.company ? (
              <S.Item>
                <Icon
                  iconName="BuildingMultiple20Regular"
                  color={IconColors.dark}
                />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.company')}: </span>
                    {contact.company.name}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.user ? (
              <S.Item>
                <Icon iconName="Person20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.owner')}: </span>
                    {contact?.user?.name}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.channel ? (
              <S.Item>
                <Icon iconName="Channel20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.channel')}: </span>
                    {contact.channel.name}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.created_at && (
              <S.Item>
                <Icon iconName="CalendarLtr20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="gray"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>{t('contact.createdAt')}: </span>
                    {formatBrazilianDate({
                      date: new Date(contact.created_at),
                    })}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            )}

            {contact?.description ? (
              <S.Item>
                <Icon iconName="Info20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography variant="body" color="gray" fontSize={12} noWrap>
                    <span>{t('contact.description')}: </span>
                    {contact.description}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {contact?.custom_fields?.map(
              (field) =>
                field.value && (
                  <S.Item key={field.id}>
                    <Icon iconName="Info20Regular" />
                    <S.ItemWrap>
                      <Typography
                        variant="body"
                        color="dark"
                        fontSize={12}
                        weight="regular"
                        ellipsis
                        noWrap
                        fill
                      >
                        <span>
                          {
                            all_custom_fields.find(
                              (f) => f.id === field.custom_field_id
                            )?.name
                          }
                        </span>{' '}
                        {field.value}
                      </Typography>
                    </S.ItemWrap>
                  </S.Item>
                )
            )}
          </S.ListWrap>
        </S.Body>
      </Card>
    </S.Container>
  );
};

export default ContactCard;
