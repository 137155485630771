import React, { ReactElement } from 'react';
import { ReactSVG } from 'react-svg';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { defaultInputValidationMessages } from 'config/defaultInputValidationMessages';
import Check from './icons/check.svg';
import Dash from './icons/dash.svg';
import * as S from './styles';
import * as T from './types';

const Checkbox = (props: T.CheckboxProps): ReactElement => {
  const {
    checked,
    name,
    onCheckedChange,
    value,
    disabled,
    defaultChecked,
    control,
    required,
    label,
  } = props;

  return (
    <>
      {control ? (
        <Controller
          rules={{
            required: {
              value: required || false,
              message: defaultInputValidationMessages.required(),
            },
          }}
          control={control}
          name={name || 'Checkbox'}
          render={({
            field: { onChange, onBlur, value, ref },
            formState: { errors },
          }) => (
            <>
              <S.CheckboxWrap>
                <S.Container
                  name={name}
                  ref={ref}
                  onCheckedChange={onChange}
                  onBlur={onBlur}
                  checked={value ? true : false}
                  value={value}
                  disabled={disabled}
                >
                  <S.Indicator>
                    {checked === 'indeterminate' ? (
                      <ReactSVG src={Dash} />
                    ) : (
                      <ReactSVG src={Check} />
                    )}
                  </S.Indicator>
                </S.Container>
                {errors && (
                  <ErrorMessage
                    errors={errors}
                    name={name || 'Checkbox'}
                    render={({ message }) => <p>{message}</p>}
                  />
                )}

                <S.LabelWrap>{label && <span>{label}</span>}</S.LabelWrap>
              </S.CheckboxWrap>
            </>
          )}
        />
      ) : (
        <S.CheckboxWrap>
          <S.Container
            checked={checked}
            name={name}
            onCheckedChange={onCheckedChange}
            value={value}
            disabled={disabled}
            defaultChecked={defaultChecked}
          >
            <S.Indicator>
              {checked === 'indeterminate' ? (
                <ReactSVG src={Dash} />
              ) : (
                <ReactSVG src={Check} />
              )}
            </S.Indicator>
          </S.Container>
          <S.LabelWrap>{label && <span>{label}</span>}</S.LabelWrap>
        </S.CheckboxWrap>
      )}
    </>
  );
};
export default Checkbox;
