import React, { ChangeEvent, ReactElement, useState } from 'react';
import * as S from './styles';
import LogoMobile from './taskflow.png';
import Logo from './taskflowwhite.png';
import { BreadCrumb, Divider, Spacer, Typography } from 'mino-ui';
import { Link } from 'react-router-dom';
import { SignUpContext } from 'presentation/contexts';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { RegisterRequestDTO } from 'domain/usecases/session/register-use-case';
import { useTranslation } from 'react-i18next';

export type TitleProps = {
  title: string;
  subtitle: string;
};

export type BreadCrumbProps = {
  title: string;
  action: () => void;
  active: boolean;
};

const SignUpPage = (): ReactElement => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState<TitleProps>({
    title: t('register.title'),
    subtitle: t('register.subtitle'),
  });

  const [form, setForm] = useState<RegisterRequestDTO>({
    plan: 'Basic',
    agreement: true,
    company: '',
    email: '',
    password: '',
    username: '',
    crmFor: '',
    companySize: '',
    segment: '',
    licenseId: '',
    quantity: 5,
  });

  const [breadCrumb, setBreadCrumb] = useState<BreadCrumbProps[]>([
    {
      title: t('register.myData'),
      action: () => undefined,
      active: step === 1 ? true : false,
    },
    {
      title: t('register.companyData'),
      action: () => undefined,
      active: step === 2 ? true : false,
    },
    {
      title: t('register.password'),
      action: () => undefined,
      active: step === 3 ? true : false,
    },
  ]);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setForm({ ...form, [name]: value });
  }

  function handleSelectInputChange(name: string, value?: string): void {
    setForm({
      ...form,
      [name]: value || '',
    });
  }

  return (
    <SignUpContext.Provider
      value={{
        setStep,
        step,
        setTitle,
        setBreadCrumb,
        breadCrumb,
        form,
        setForm,
        handleInputChange,
        handleSelectInputChange,
      }}
    >
      <S.Container>
        <S.TextColumn>
          <img src={Logo} />
          <span>Taskflow CRM v.1.3.0-beta.2</span>
        </S.TextColumn>

        <S.WrapperForm>
          <S.LogoMobile>
            <img src={LogoMobile} />
          </S.LogoMobile>

          <S.StyledForm>
            <S.WrapperBreadCrumb>
              <BreadCrumb items={breadCrumb} />
            </S.WrapperBreadCrumb>
            <Spacer height={82} heightMobile={73} />
            <Typography
              noWrap={true}
              variant="h2"
              style={{ fontWeight: 500 }}
              color="dark"
            >
              {title.title}
            </Typography>
            <Spacer height={12} />
            <Typography variant="body" style={{ fontWeight: 500 }} color="dark">
              {title.subtitle}

              {step !== 3 && (
                <S.Highlight>
                  <Link to="/login">{t('register.login')}</Link>
                </S.Highlight>
              )}
            </Typography>
            <Spacer height={22} />
            <Divider color="dark" />
            <Spacer height={50} />

            {step === 1 && <StepOne />}
            {step === 2 && <StepTwo />}
            {step === 3 && <StepThree />}
          </S.StyledForm>
        </S.WrapperForm>
      </S.Container>
    </SignUpContext.Provider>
  );
};

export default SignUpPage;
